import { styled } from "@mui/material";
import { CBaseButton } from "../base";

export const TransparentButtonStyled = styled(CBaseButton)`
  /* background-image: linear-gradient(to right, var(--Blue), var(--White-10)); */
  width: ${(props) => props.width};
  min-width: ${(props) => props.width};
  height: ${(props) => props.height};
  padding: 20px 40px;
  /* background-color: transparent; */
  border: 2px solid var(--Black);
  text-transform: none;

  background: linear-gradient(to left, transparent 50%, rgba(0, 0, 0, 0.1) 50%);
  background-size: 200% 100%;
  background-position: right;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: all 0.25s ease;
    border-radius: 100px;
    transition-duration: 0.5s;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
  &:hover::before {
    background-color: transparent;
    box-shadow: inset 0 0 0 38.4px rgba(0, 0, 0, 0.1);
  }
  &:hover {
    /* background: rgba(0, 0, 0, 0.1); */
    /* transition: all 0.75s linear; */
    background-color: unset;
    border: 2px solid #000000;
    box-shadow: unset;
    /* background-position: left; */
  }
`;
