import { css, styled } from "@mui/material";
import {
  COLUMN_ALIGN_START__JUSTIFY_START,
  GradientFontStyle,
  MOBILE_WIDTH,
  ROW_ALIGN_START__SPACE_B,
  ROW_JUSTIFY_START__ALIGN_CENTER,
  TABLET_WIDTH,
} from "../../styles/global-styles";
import {
  TEXT_11,
  TEXT_40,
  TEXT_56,
  TEXT_64,
} from "../../styles/globalTypography";
import { breakpoints } from "../../styles/media-queries";

export const DownloadStyled = styled("section")`
  background-color: var(--Alice-Blue);
  border-radius: 16px;
  display: flex;
  justify-content: center;

  @media (min-width: ${breakpoints.xxs}px) and (max-width: ${breakpoints.sm}px) {
    height: auto;
    border-radius: 0px;
  }
  @media (max-width: ${breakpoints.xxs}px) {
    height: auto;
    border-radius: 0px;
  }

  & .content {
    ${ROW_ALIGN_START__SPACE_B}
    width: 1283px;
    margin: auto;
    @media (min-width: ${breakpoints.lg}px) and (max-width: ${breakpoints.xxl}px) {
      transform: scale(0.8);
      transform-origin: top;
      height: 578.4px;
    }
    @media (min-width: ${breakpoints.sm}px) and (max-width: ${breakpoints.lg}px) {
      min-width: 1296px;
      transform: scale(0.6);
      transform-origin: center 0;
      height: 433.8px;
    }
    @media (min-width: ${breakpoints.xxs}px) and (max-width: ${breakpoints.sm}px) {
      ${TABLET_WIDTH}
      flex-direction: column;
      height: auto;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      ${MOBILE_WIDTH}
      flex-direction: column;
      height: auto;
      align-items: center;
    }
  }
  & .download-left-column {
    ${COLUMN_ALIGN_START__JUSTIFY_START}
    gap:56px;
    max-width: 420px;
    margin-top: 156px;
    @media (min-width: ${breakpoints.xxs}px) and (max-width: ${breakpoints.sm}px) {
      margin-top: 137px;
      margin-bottom: 54px;
      align-items: center;
      max-width: unset;
      width: 100%;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      margin-top: 106px;
      margin-bottom: 68px;
      align-items: center;
    }
  }

  & .download-badge {
    height: 45px;
    background: transparent;
    border-radius: 40px;
    padding: 16px 32px;
    justify-content: flex-start;
    @media (min-width: ${breakpoints.sm}px) {
      padding-left: 0;
    }
    &:hover {
      background: transparent;
      box-shadow: none;
      cursor: unset;
    }
    &::before {
      display: none;
    }
    &:hover::before {
      display: none;
    }
  }

  & .download-badge-text {
    min-width: 101px;
    ${TEXT_11}
    text-transform: uppercase;
  }

  & .download-right-column {
    pointer-events: none;
    margin-top: -54px;
    @media (min-width: ${breakpoints.xxs}px) and (max-width: ${breakpoints.sm}px) {
      width: 100%;
      margin: 0px;
      overflow: hidden;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      width: 100%;
      margin: 0px;
      overflow: hidden;
      padding: 0 10px;
    }
  }
  & .download-white-title {
    ${TEXT_56}
    @media (min-width: ${breakpoints.xxs}px) and (max-width: ${breakpoints.sm}px) {
      ${TEXT_64}
      text-align: center;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      ${TEXT_40}
      text-align: center;
    }
  }
  & .download-title-container {
  }
  & .download-button-container {
    ${ROW_JUSTIFY_START__ALIGN_CENTER}
    gap: 8px;
    width: 271px;
    @media (max-width: ${breakpoints.xxs}px) {
      flex-direction: column;
    }
  }

  & .scale-restore {
    @media (min-width: ${breakpoints.lg}px) and (max-width: ${breakpoints.xxl}px) {
      transform: scale(1.25);
      margin-left: 30px;
    }
    @media (min-width: ${breakpoints.sm}px) and (max-width: ${breakpoints.lg}px) {
      transform: scale(1.66);
      margin-left: 75px;
    }
  }

  & .gradient-font {
    ${GradientFontStyle}
  }
  & .hide-for-tablet {
    display: inline;
    color: var(--Black);
    @media (min-width: ${breakpoints.xxs}px) and (max-width: ${breakpoints.sm}px) {
      display: none;
    }
  }
  & .show-for-tablet {
    display: none;
    @media (min-width: ${breakpoints.xxs}px) and (max-width: ${breakpoints.sm}px) {
      display: inline;
    }
  }

  ${(props) =>
    props?.containerVisibility?.download
      ? css`
          visibility: visible;
          transition: visibility 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94),
            opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
          opacity: 1;
        `
      : css`
          visibility: hidden;
          opacity: 0;
          transition: visibility 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94),
            opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        `}
`;
