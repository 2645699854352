import CTypography from "../../../componets/typography";
import streamlineIcon from "../../../assets/icons/Streamline.png";
import { SecondFeatureStyled } from "./second-styled";
export const SecondFeature = ({ containerVisibility }) => {
  return (
    <SecondFeatureStyled id="SF" containerVisibility={containerVisibility}>
      <div className="SF-container">
        <div className="text-column">
          <div className="top-section">
            <CTypography
              color="var(--Black)"
              weight="bold"
              className="black-title"
            >
              Streamline compliance workflows
            </CTypography>
            <CTypography
              color="var(--Grey)"
              weight="medium"
              size="larger"
              className="grey-body"
            >
              While digitizing your documents, CorePass also offers a seamless
              Know-Your-Client (KYC) verification and compliance pre-check. This
              ensures that your digital identity is not just easily accessible,
              but also trusted and verified by various institutions and
              businesses.
            </CTypography>
          </div>
          <ul>
            <li>
              Seamlessly integrate with 3rd parties - governments, businesses,
              and other organizations.
            </li>
            <li>
              Validate your data transfer and block unauthorized activities by
              proving the data-trail.
            </li>
          </ul>
        </div>
        <img src={streamlineIcon} alt="streamlineIcon" className="pic-column" />
      </div>
    </SecondFeatureStyled>
  );
};
