import { useContext } from "react";
import CAccordions from "../../componets/accordion";
import { CGradientButton } from "../../componets/button/gradient";
import { CTransparentButton } from "../../componets/button/transparent";
import CTypography from "../../componets/typography";
import { QAStyled } from "./qa-styled";
import { accordionOptions } from "./qs-data";
import { CustomCursorContext } from "../../contexts/cursor";
import { useWindowSize } from "../../contexts/screen-size";
import { Contact_Us_Link } from "../../constants";

const QA = ({ containerVisibility, sectionRef }) => {
  const { setType } = useContext(CustomCursorContext);
  const { windowHeight } = useWindowSize();

  return (
    <QAStyled
      ref={(e) => {
        sectionRef.current.Contact = e;
      }}
      screenHeight={windowHeight.current}
      containerVisibility={containerVisibility}
      id="QA"
    >
      <div className="left-column">
        <CGradientButton className="qa-button">
          <CTypography
            color="var(--Blue)"
            weight="bold"
            className="qa-button-text"
          >
            Frequently asked questions
          </CTypography>
        </CGradientButton>
        <div className="title-container">
          <CTypography
            color="var(--Black)"
            weight="bold"
            className="black-title"
          >
            Want to <br className="qa-title-breaker" /> know more?
          </CTypography>
          <CTypography
            color="var(--Black)"
            weight="bold"
            className="black-title gradient-font"
          >
            We have the answers.
          </CTypography>
        </div>
        <CTypography
          color="var(--Grey)"
          weight="medium"
          size="larger"
          className="grey-text"
        >
          In our FAQ section, we acknowledge you might have questions about our
          products and services. To assist you, we've compiled a list of the
          most frequently asked questions, enabling you to quickly find the
          information you need.
        </CTypography>
        <div className="magnet-container ">
          <a target="_blank" rel="noopener noreferrer" href={Contact_Us_Link}>
            <CTransparentButton
              width="175px"
              height="64px"
              className="magnet-item"
              onMouseEnter={() => {
                setType("hover");
              }}
              onMouseLeave={() => {
                setType("");
              }}
            >
              <CTypography
                color="var(--Black)"
                size="larger"
                weight="bold"
                className="contact-text"
              >
                Contact Us
              </CTypography>
            </CTransparentButton>
          </a>
        </div>
      </div>
      <div className="right-column">
        <CAccordions accordionOptions={accordionOptions} />
      </div>
    </QAStyled>
  );
};
export default QA;
