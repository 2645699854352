import { CGradientButton } from "../../componets/button/gradient";
import { CTransparentButton } from "../../componets/button/transparent";
import CTypography from "../../componets/typography";
import { IntroductionStyled, IntroductionWrapper } from "./intro-styled";
import doubleCell from "../../assets/icons/double-cell.png";
// import introIcon from "../../assets/icons/introLogo.png";
import introIcon from "../../assets/icons/corepass-hero.png";
import { useContext, useEffect, useRef, useState } from "react";
import { ModalContext } from "../../contexts/modal";
import TradeMarkIcon from "../../assets/icons/trademark-3.svg";
import { IntroEffect } from "../../utils/intro-effect";
import { breakpoints } from "../../styles/media-queries";
import { CustomCursorContext } from "../../contexts/cursor";
import { useWindowSize } from "../../contexts/screen-size";

export const Introduction = ({ sectionRef }) => {
  let { setIsOpen } = useContext(ModalContext);
  const { windowWidth } = useWindowSize();
  const [hideCutOff, setHideCutOff] = useState(false);
  const { setType } = useContext(CustomCursorContext);

  useEffect(() => {
    if (windowWidth >= breakpoints.sm) {
      var { handleMouseMove, handleMouseOut } = IntroEffect(
        "hero-image-container",
        "introIcon",
        "TradeMark-Icon"
      );
    }
    return () => {
      let container = document.getElementById("hero-image-container");
      container?.removeEventListener("mousemove", handleMouseMove);
      container?.removeEventListener("mouseout", handleMouseOut);
    };
  }, [windowWidth]);
  const introductionRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const goalComponent = sectionRef.current.AboutCorePass;
      const introductionElement = introductionRef.current;

      // Calculate the scroll position and distance from the top of the goal component
      const scrollTop = window.scrollY || document.documentElement.scrollTop;

      const distanceToTop = goalComponent.getBoundingClientRect().top;

      distanceToTop < 300 ? setHideCutOff(true) : setHideCutOff(false);

      // Update the styles of the introduction component
      // introductionElement.style.opacity = opacity;
      if (distanceToTop > 0) {
        scrollTop * 0.15 < 136
          ? (introductionElement.style.transform = `translateY(${
              scrollTop * 0.15
            }px)`)
          : (introductionElement.style.transform = `translateY(-136px)`);
        introductionElement.style.marginBottom =
          scrollTop * 0.3 < 270 ? `-${scrollTop * 0.3}px` : "-270px";
      }
    };
    const handleScrollTablet = () => {
      const goalComponent = sectionRef.current.AboutCorePass;
      const distanceToTop = goalComponent.getBoundingClientRect().top;
      distanceToTop < 300 ? setHideCutOff(true) : setHideCutOff(false);
    };
    if (windowWidth >= breakpoints.sm) {
      window.addEventListener("scroll", handleScroll);
    } else {
      window.addEventListener("scroll", handleScrollTablet);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("scroll", handleScrollTablet);
    };
  }, [sectionRef, windowWidth]);

  return (
    <IntroductionWrapper
      id="introWrapper"
      ref={introductionRef}
      hideCutOff={hideCutOff}
    >
      <IntroductionStyled className="slide-in-bottom">
        <div className="intro-container">
          <div className="leftColumn">
            <CGradientButton className="intro-badge">
              <CTypography
                color="var(--Blue)"
                weight="bold"
                className="button-text"
              >
                PUT THE YOU IN UNIQUE
              </CTypography>
            </CGradientButton>
            <div className="middle-section">
              <CTypography className="introTitle" weight="bold">
                Your Gateway
              </CTypography>
              <CTypography className="introTitle" weight="bold">
                to a Decentralized,
              </CTypography>
              <CTypography className="introTitle gradientFont" weight="bold">
                Privacy-First Future
              </CTypography>
            </div>
            <CTypography
              className="introBody "
              size="largest"
              color="var(--Grey)"
              weight="medium"
            >
              CorePass revolutionizes digital identity management by giving you
              full control over your personal data, dictating how it's shared
              and with whom. Experience a new level of privacy and security, and
              redefine the way you interact with the digital world.
            </CTypography>
            <div className="magnet-container ">
              <CTransparentButton
                width="196px"
                height="64px"
                className="magnet-item"
                onClick={() => {
                  setIsOpen(true);
                }}
                onMouseEnter={() => {
                  setType("hover");
                }}
                onMouseLeave={() => {
                  setType("");
                }}
              >
                <CTypography
                  color="var(--Black)"
                  size="larger"
                  weight="bold"
                  className="corepass-button-text "
                >
                  Get CorePass
                </CTypography>
              </CTransparentButton>
            </div>
            <div id="hero-image-container" className="hero-image-container">
              <img src={introIcon} alt="intro-icon" id="introIcon" />
            </div>
            <img
              src={doubleCell}
              alt="double-cell"
              className="intro-mobile-logo"
            />
          </div>
          <img
            src={TradeMarkIcon}
            alt="TradeMark-Icon"
            id="TradeMark-Icon"
            className="TradeMark-Icon"
          />
        </div>
      </IntroductionStyled>
    </IntroductionWrapper>
  );
};
export default Introduction;
