import { useContext, useLayoutEffect } from "react";
import { CGradientButton } from "../../componets/button/gradient";
import CTypography from "../../componets/typography";

import {
  Service,
  Introduction,
  PersonalDataAndPrivacy,
  ProhibitedActivities,
  License,
  Rights,
  Requirements,
  Fees,
  LimitationOfLiabilities,
  IntellectualProperty,
  SuspensionAndTermination,
  WarrantyDisclaimer,
  Indemnification,
  LiabilityEvents,
  GoverningLaw,
  AvailabilityAndChanges,
} from "./data";
import {
  BodyWrapper,
  ItemBodyBlack,
  ItemBodyBlackWithBullet,
  ItemBodyGrey,
  ItemBodyList,
  ItemBodyListOneCol,
  ItemContainer,
  ItemTitle,
  ItemTitlePrefix,
  ListItem,
  MainTitle,
  NestedBodyList,
  PrivacyStyled,
  TitleWrapper,
} from "./terms-styled";
import { NavLink, useLocation } from "react-router-dom";
import { ROUTENAMES } from "../../constants";
import { CustomCursorContext } from "../../contexts/cursor";

export const TermsConditions = () => {
  const { pathname } = useLocation();
  const { setType } = useContext(CustomCursorContext);
  useLayoutEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [pathname]);
  return (
    <PrivacyStyled>
      <TitleWrapper>
        <CGradientButton className="terms-badge">
          <CTypography
            color="var(--Blue)"
            weight="bold"
            className="terms-button-text"
          >
            Legal terms
          </CTypography>
        </CGradientButton>
        <MainTitle color="var(--Black)" weight="bold" className="black-title">
          Terms and Conditions
        </MainTitle>
        <ItemBodyGrey>Last Updated: 21.10.2024</ItemBodyGrey>
      </TitleWrapper>
      <ItemContainer>
        <ItemTitle>
          <ItemTitlePrefix> 1.</ItemTitlePrefix>
          {Introduction.title}
        </ItemTitle>
        <ItemBodyGrey>{Introduction.body}</ItemBodyGrey>
        <ItemBodyGrey>{Introduction.body2}</ItemBodyGrey>
        <ItemBodyGrey>{Introduction.body3}</ItemBodyGrey>
        <ItemBodyGrey>
          {Introduction.body4}
          <NavLink
            onMouseEnter={() => {
              setType("hover");
            }}
            onMouseLeave={() => {
              setType("");
            }}
            to={ROUTENAMES.RootPage}
          >
            https://corepass.net,
          </NavLink>
          {Introduction.body4Con}
        </ItemBodyGrey>
      </ItemContainer>
      <ItemContainer>
        <ItemTitle>
          <ItemTitlePrefix> 2.</ItemTitlePrefix>
          {Service.title}
        </ItemTitle>
        <ItemBodyGrey>{Service.body}</ItemBodyGrey>
        <ItemBodyGrey>{Service.body2}</ItemBodyGrey>
      </ItemContainer>
      <ItemContainer>
        <ItemTitle>
          <ItemTitlePrefix> 3.</ItemTitlePrefix>
          {PersonalDataAndPrivacy.title}
        </ItemTitle>
        <BodyWrapper>
          <ItemBodyGrey>
            <span> {PersonalDataAndPrivacy.subtitle}</span>
            {PersonalDataAndPrivacy.body}
          </ItemBodyGrey>
        </BodyWrapper>
        <NavLink
          to={ROUTENAMES.Privacy}
          onMouseEnter={() => {
            setType("hover");
          }}
          onMouseLeave={() => {
            setType("");
          }}
        >
          <ItemBodyBlackWithBullet>
            {PersonalDataAndPrivacy.list}
          </ItemBodyBlackWithBullet>
        </NavLink>
      </ItemContainer>
      <ItemContainer>
        <ItemTitle>
          <ItemTitlePrefix> 4.</ItemTitlePrefix>
          {License.title}
        </ItemTitle>
        <ItemBodyGrey>{License.body}</ItemBodyGrey>
      </ItemContainer>

      <ItemContainer>
        <ItemTitle>
          <ItemTitlePrefix> 5.</ItemTitlePrefix>
          {ProhibitedActivities.title}
        </ItemTitle>
        <ItemBodyGrey>{ProhibitedActivities.body}</ItemBodyGrey>
        <ItemBodyGrey>{ProhibitedActivities.body2}</ItemBodyGrey>
        <ItemBodyGrey>{ProhibitedActivities.body3}</ItemBodyGrey>
        <ItemBodyList>
          {ProhibitedActivities.list?.map((item, index) => (
            <ListItem key={index} className={`item${index + 1}`}>
              {<span>{item}</span>}
            </ListItem>
          ))}
        </ItemBodyList>
      </ItemContainer>
      <ItemContainer>
        <ItemTitle>
          <ItemTitlePrefix> 6.</ItemTitlePrefix>
          {Rights.title}
        </ItemTitle>
        <ItemBodyGrey>{Rights.body}</ItemBodyGrey>
        <ItemBodyListOneCol>
          {Rights.list?.map((item, index) => (
            <ListItem key={index} className={`item${index + 1}`}>
              {<span>{item}</span>}
            </ListItem>
          ))}
        </ItemBodyListOneCol>
      </ItemContainer>
      <ItemContainer>
        <ItemTitle>
          <ItemTitlePrefix> 7.</ItemTitlePrefix>
          {Requirements.title}
        </ItemTitle>
        <ItemBodyGrey>{Requirements.body}</ItemBodyGrey>
        <ItemBodyListOneCol>
          {Requirements.list?.map((item, index) => (
            <ListItem key={index} className={`item${index + 1}`}>
              {<span>{item}</span>}
            </ListItem>
          ))}
        </ItemBodyListOneCol>
      </ItemContainer>
      <ItemContainer>
        <ItemTitle>
          <ItemTitlePrefix> 8.</ItemTitlePrefix>
          {LimitationOfLiabilities.title}
        </ItemTitle>
        <ItemBodyGrey>{LimitationOfLiabilities.body}</ItemBodyGrey>
        <ItemBodyGrey>{LimitationOfLiabilities.body2}</ItemBodyGrey>
        <ItemBodyList>
          {LimitationOfLiabilities.list?.map((item, index) => (
            <ListItem key={index} className={`item${index + 1}`}>
              {<span>{item}</span>}
            </ListItem>
          ))}
        </ItemBodyList>
        <ItemBodyGrey>{LimitationOfLiabilities.body3}</ItemBodyGrey>
      </ItemContainer>
      <ItemContainer>
        <ItemTitle>
          <ItemTitlePrefix> 9.</ItemTitlePrefix>
          {Fees.title}
        </ItemTitle>
        <ItemBodyGrey>{Fees.body}</ItemBodyGrey>
        <ItemBodyGrey>{Fees.body2}</ItemBodyGrey>
        <ItemBodyGrey>{Fees.body3}</ItemBodyGrey>
      </ItemContainer>
      <ItemContainer>
        <ItemTitle>
          <ItemTitlePrefix> 10.</ItemTitlePrefix>
          {IntellectualProperty.title}
        </ItemTitle>
        <ItemBodyGrey>{IntellectualProperty.body}</ItemBodyGrey>
        <ItemBodyGrey>{IntellectualProperty.body2}</ItemBodyGrey>
        <ItemBodyGrey>{IntellectualProperty.body3}</ItemBodyGrey>
        <ItemBodyGrey>{IntellectualProperty.body4}</ItemBodyGrey>
      </ItemContainer>
      <ItemContainer>
        <ItemTitle>
          <ItemTitlePrefix> 11.</ItemTitlePrefix>
          {SuspensionAndTermination.title}
        </ItemTitle>
        <ItemBodyGrey>{SuspensionAndTermination.body}</ItemBodyGrey>
        <ItemBodyGrey>{SuspensionAndTermination.body2}</ItemBodyGrey>
      </ItemContainer>
      <ItemContainer>
        <ItemTitle>
          <ItemTitlePrefix> 12.</ItemTitlePrefix>
          {WarrantyDisclaimer.title}
        </ItemTitle>
        <ItemBodyGrey>{WarrantyDisclaimer.body}</ItemBodyGrey>
        <ItemBodyGrey>{WarrantyDisclaimer.body2}</ItemBodyGrey>
      </ItemContainer>
      <ItemContainer>
        <ItemTitle>
          <ItemTitlePrefix> 13.</ItemTitlePrefix>
          {Indemnification.title}
        </ItemTitle>
        <ItemBodyGrey>{Indemnification.body}</ItemBodyGrey>
      </ItemContainer>
      <ItemContainer>
        <ItemTitle>
          <ItemTitlePrefix> 14.</ItemTitlePrefix>
          {LiabilityEvents.title}
        </ItemTitle>
        <ItemBodyGrey>{LiabilityEvents.body}</ItemBodyGrey>
        <ItemBodyListOneCol>
          {LiabilityEvents.list?.map((item, index) => (
            <ListItem key={index} className={`item${index + 1}`}>
              {<span>{item}</span>}
              {index === 0 && (
                <NestedBodyList>
                  {LiabilityEvents.indexZeroNestedList?.map((item, index) => (
                    <ListItem key={index} className={`item${index + 1}`}>
                      {<span>{item}</span>}
                    </ListItem>
                  ))}
                </NestedBodyList>
              )}
            </ListItem>
          ))}
        </ItemBodyListOneCol>
      </ItemContainer>
      <ItemContainer>
        <ItemTitle>
          <ItemTitlePrefix> 15.</ItemTitlePrefix>
          {GoverningLaw.title}
        </ItemTitle>
        <ItemBodyBlack>{GoverningLaw.bodyBlack}</ItemBodyBlack>
        <ItemBodyGrey>{GoverningLaw.body}</ItemBodyGrey>
        <ItemBodyListOneCol>
          {GoverningLaw.list?.map((item, index) => (
            <ListItem key={index} className={`item${index + 1}`}>
              {<span>{item}</span>}
            </ListItem>
          ))}
        </ItemBodyListOneCol>
      </ItemContainer>
      <ItemContainer>
        <ItemTitle>
          <ItemTitlePrefix> 16.</ItemTitlePrefix>
          {AvailabilityAndChanges.title}
        </ItemTitle>
        <ItemBodyGrey>
          {AvailabilityAndChanges.body}
          <NavLink
            to={ROUTENAMES.RootPage}
            onMouseEnter={() => {
              setType("hover");
            }}
            onMouseLeave={() => {
              setType("");
            }}
          >
            (https://corepass.net)
          </NavLink>
          {AvailabilityAndChanges.bodyCon}
        </ItemBodyGrey>
        <ItemBodyGrey>{AvailabilityAndChanges.body2}</ItemBodyGrey>
      </ItemContainer>
    </PrivacyStyled>
  );
};
