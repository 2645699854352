import { css, styled } from "@mui/material";
import {
  COLUMN_ALIGN_CENTER__SPACE_B,
  DESKTOP_WIDTH,
  MOBILE_WIDTH,
  ROW_ALIGN_CENTER__SPACE_B,
} from "../../styles/global-styles";
import {
  TEXT_11,
  TEXT_16_H24,
  TEXT_18,
  TEXT_20_500,
  TEXT_40,
  TEXT_56,
} from "../../styles/globalTypography";
import { breakpoints } from "../../styles/media-queries";

export const BussinessStyled = styled("section")`
  background-color: var(--Alice-Blue);
  padding: 141px 0 142px;
  border-radius: 16px;
  margin-bottom: 382px;
  scroll-margin: 100px;
  position: relative;
  z-index: 1;
  @media (max-width: ${breakpoints.m1024}px) {
    margin-bottom: 191px;
  }
  @media (max-width: ${breakpoints.sm}px) {
    padding: 148px 0;
    border-radius: 0;
    margin-bottom: 151px;
  }
  @media (max-width: ${breakpoints.xxs}px) {
    padding: 132px 0;
    border-radius: 0;
    margin-bottom: 144px;
  }
  & .centerContent {
    position: relative;
    ${COLUMN_ALIGN_CENTER__SPACE_B};
    gap: 48px;
    max-width: 1081px;
    margin: auto;
    @media (min-width: ${breakpoints.sm}px) and (max-width: ${breakpoints.xxl}px) {
      ${DESKTOP_WIDTH}
    }
    @media (max-width: ${breakpoints.sm}px) {
      width: 89%;
      gap: 56px;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      ${MOBILE_WIDTH}
      gap: 56px;
    }
  }
  & .grey-button {
    width: 250px;
    height: 45px;
    background: transparent;
    border-radius: 40px;
    &:hover {
      background: transparent;
      box-shadow: none;
      cursor: unset;
    }
  }
  & .bussiness-button-text {
    min-width: 156px;
    height: 13px;
    ${TEXT_11}
  }
  & .white-title {
    max-width: 100%;
    ${TEXT_56}
    text-align: center;
    @media (max-width: ${breakpoints.sm}px) {
      ${TEXT_40}
      line-height: 56px;
      text-align: center;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      ${TEXT_40}
      text-align: center;
    }
  }
  & .white-body {
    width: 100%;
    ${TEXT_20_500}
    text-align: center;

    @media (max-width: ${breakpoints.sm}px) {
      ${TEXT_18}
      text-align: center;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      ${TEXT_16_H24}
    }
  }
  & .button-container {
    ${ROW_ALIGN_CENTER__SPACE_B}
    padding: 0px;
    gap: 24px;
    max-width: 384px;
    margin: auto;
    @media (max-width: ${breakpoints.xxs}px) {
      flex-direction: column;
    }
  }
  & .contained-button {
    padding: 20px 40px;
    width: 183px;
    min-width: 183px;
    height: 64px;
    background: linear-gradient(
      to left,
      rgba(16, 102, 223, 1) 50%,
      #1555b0 50%
    );
    background-size: 200% 100%;
    background-position: right;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      transition: all 0.25s ease;
      border-radius: 100px;
      transition-duration: 0.5s;
      box-shadow: inset 0 0 0 1px #1555b0;
    }
    &:hover::before {
      background-color: transparent;
      box-shadow: inset 0 0 0 64px #1555b0;
    }
    &:hover {
      box-shadow: unset;
      background-color: unset;
    }
  }
  & .contained-button-text {
    width: 103px;
    height: 24px;
    line-height: 24px;
    letter-spacing: -0.02em;
    text-transform: none;
    z-index: 1;
    position: relative;
  }
  & .button-text-white {
    min-width: 97px;
    line-height: 24px;
    letter-spacing: -0.02em;
  }
  & .WBorder-button {
    padding: 20px 40px;
    width: 177px;
    min-width: 177px;
    height: 64px;
    border: 2px solid var(--Black);
    border-radius: 100px;

    background: linear-gradient(
      to left,
      transparent 50%,
      rgba(255, 255, 255, 0.1) 50%
    );
    background-size: 200% 100%;
    background-position: right;
  }
  & .logoTop {
    position: absolute;
    top: -242px;
    left: -183px;
    width: 264px;
    @media (max-width: ${breakpoints.sm}px) {
      width: 220px;
      top: -230px;
      left: -93px;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      width: 220px;
      top: -212px;
      left: -113px;
    }
  }
  & .logoBottom {
    position: absolute;
    bottom: -305px;
    right: -141px;
    width: 248px;
    @media (max-width: ${breakpoints.sm}px) {
      width: 242.42px;
      bottom: -312px;
      right: -100px;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      width: 242.42px;
      bottom: -297px;
      right: -110px;
    }
  }
  & .responsive-breaks {
    display: none;
    @media (max-width: ${breakpoints.xxs}px) {
      display: block;
    }
  }

  .magnet-container {
    position: relative;
    cursor: pointer;
    margin: -5px;
  }

  .magnet-item {
    position: relative;
    display: inline-block;
    margin: 5px;
    transition: transform 0.75s linear;
  }

  .floating {
    animation-name: floating;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }

  @keyframes floating {
    0%,
    100% {
      transform: translate(0, -30px);
    }
    50% {
      transform: translate(0, 25px);
    }
  }
  .floating-bottom {
    animation-name: floating-bottom;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }

  @keyframes floating-bottom {
    0%,
    100% {
      transform: translate(0, -35px);
    }
    50% {
      transform: translate(0, 25px);
    }
  }

  ${(props) =>
    props?.containerVisibility?.integration
      ? css`
          visibility: visible;
          transition: visibility 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94),
            opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
          opacity: 1;
        `
      : css`
          visibility: hidden;
          opacity: 0;
          transition: visibility 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94),
            opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        `}
`;
