export const IntroEffect = (containerParam, target1, target2) => {
  let container = document.getElementById(containerParam);
  const targetElement = document.getElementById(target1);
  const targetElement2 = document.getElementById(target2);

  let maxXTranslate = 0;
  let maxYTranslate = 0;
  // define a function named handleMouseMove
  function handleMouseMove(e) {
    targetElement.style.transition = "all 0.05s ease-in-out";
    targetElement2.style.transition = "all 0.05s ease-in-out";
    const position = container.getBoundingClientRect();
    const x = e.clientX - position.left - position.width / 2;
    const y = e.clientY - position.top - position.height / 2;
    // const x = e.clientX - window.innerWidth / 2;
    // const y = e.clientY - window.innerHeight / 2;
    Math.abs(x * 0.015) < 30
      ? (maxXTranslate = x * 0.015)
      : x > 0
      ? (maxXTranslate = 30)
      : (maxXTranslate = -30);
    Math.abs(y * 0.015) < 30
      ? (maxYTranslate = y * 0.015)
      : y > 0
      ? (maxYTranslate = 30)
      : (maxYTranslate = -30);
    targetElement.style.transform =
      "translate(" + -maxXTranslate + "px, " + -maxYTranslate + "px)";
    targetElement2.style.transform =
      "translate(" + maxXTranslate + "px, " + maxYTranslate + "px)";
    //   });
  }

  container.addEventListener("mousemove", handleMouseMove);

  function handleMouseOut(e) {
    // targetElement.style.transition = "all 0.1s ease-in-out";
    // targetElement.style.transition = "all 0.1s ease-in-out";
    targetElement.style.transition = "all 0.5s ease-in-out";
    targetElement2.style.transition = "all 0.5s ease-in-out";
    targetElement.style.transform = "translate(0px, 0px)";
    targetElement2.style.transform = "translate(0px, 0px)";
  }
  container.addEventListener("mouseout", handleMouseOut);
  return { handleMouseMove, handleMouseOut };
};
