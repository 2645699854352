import CTypography from "../../../componets/typography";
import PrivacyIcon from "../../../assets/icons/privacy-security.png";
import { ThirdFeatureStyled } from "./third-styled";
export const ThirdFeature = ({ containerVisibility }) => {
  return (
    <ThirdFeatureStyled id="TF" containerVisibility={containerVisibility}>
      <div className="TF-container">
        <img src={PrivacyIcon} alt="PrivacyIcon" className="pic-column" />
        <div className="text-column">
          <div className="top-section">
            <CTypography
              color="var(--Black)"
              weight="bold"
              className="black-title"
            >
              Privacy & Security <br className="third-title-break" /> an
              essential necessity
            </CTypography>
            <CTypography
              color="var(--Grey)"
              weight="medium"
              size="larger"
              className="grey-body"
            >
              CorePass is constructed on a serverless, decentralized
              infrastructure designed to shield your data from unauthorized
              access. By leveraging biometric security and advanced
              cryptography, CorePass assures the safety and security of your
              data, making it visible solely to authorized parties.
            </CTypography>
          </div>
          <ul>
            <li>Eliminate risks associated with centralized data storage.</li>
            <li>
              Empower yourself by taking control over your personal information.
            </li>
          </ul>
        </div>
      </div>
    </ThirdFeatureStyled>
  );
};
