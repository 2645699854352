import styled from "@emotion/styled";
import { breakpoints } from "../../styles/media-queries";
import { css } from "@emotion/react";

export const StyledCursor = styled("img")`
  z-index: 10000;
  position: fixed;
  pointer-events: none;
  width: 8px;
  height: 8px;
  transition: width 0.5s, height 0.5s, margin 0.5s;
  @media (max-width: ${breakpoints.sm}px) {
    display: none;
  }

  ${(props) =>
    props?.type === "hover" &&
    css`
      width: 72px;
      height: 72px;
      margin-top: -32px;
      margin-left: -32px;
    `}
`;

// .main-cursor {
//     z-index: 10000;
//     pointer-events: none;
//     position: fixed;
//     @media (max-width: 800px) {
//       display: none;
//     }
//   }

//   .main-cursor {
//     z-index: 10000;
//     position: fixed;
//     pointer-events: none;
//     width: 8px;
//     height: 8px;
//     transition: width 0.5s, height 0.5s, margin 0.5s;
//   }

//   .hover {
//     width: 72px;
//     height: 72px;
//     margin-top: -32px;
//     margin-left: -32px;
//   }
