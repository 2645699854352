import { StartContent } from "../features/components/start-content";
import Goal from "../goal";
import Introduction from "../introduction";
import { ParallaxWrapperStyled } from "./parallax-styled";

export const ParallaxWrapper = ({ sectionRef, containerVisibility }) => {
  return (
    <ParallaxWrapperStyled
      ref={(e) => (sectionRef.current.Hero = e)}
      id="parallax"
    >
      <Introduction sectionRef={sectionRef} />
      <Goal sectionRef={sectionRef} containerVisibility={containerVisibility} />
      <StartContent
        containerVisibility={containerVisibility}
        sectionRef={sectionRef}
      />
    </ParallaxWrapperStyled>
  );
};
