import React, { useEffect, useLayoutEffect } from "react";
import { ParallaxWrapper } from "../parallax-wrapper";
import { scrollObservers } from "../../utils/scroll-observers";
import Features from "../features";
import Benifits from "../benifits";
import BussinessIntegration from "../bussiness-integration";
import Ecosystem from "../ecosystem";
import QA from "../q-a";
import { useLocation } from "react-router-dom";
import { scrollToSection } from "../header/utils/scroll-section";
import { delay } from "../../utils/delay";
import { useWindowSize } from "../../contexts/screen-size";

export const IndexPage = ({
  sectionRef,
  containerVisibilityRef,
  setContainerVisibility,
  containerVisibility,
}) => {
  const location = useLocation();
  const { windowWidth } = useWindowSize();
  useEffect(() => {
    let { observerInitialBorder, observer1, observer10 } = scrollObservers(
      containerVisibilityRef,
      setContainerVisibility
    );
    observerInitialBorder.observe(document.getElementById("introWrapper"));
    observer10.observe(document.getElementById("GoalLayer"));
    observer1.observe(document.getElementById("featureIntro"));
    observer10.observe(document.getElementById("FF"));
    observer10.observe(document.getElementById("SF"));
    observer10.observe(document.getElementById("TF"));
    observer10.observe(document.getElementById("benifits"));
    observer10.observe(document.getElementById("integration"));
    observer10.observe(document.getElementById("ecosystem"));
    observer10.observe(document.getElementById("QA"));
    // eslint-disable-next-line
  }, []);

  useLayoutEffect(() => {
    if (location?.state?.name) {
      const scrollToTheSection = async () => {
        window.scrollTo(0, 0);
        await delay(100);
        scrollToSection(sectionRef, location?.state?.name, windowWidth);
      };
      scrollToTheSection();
    }
    window.history.replaceState({}, document.title);
    // eslint-disable-next-line
  }, [location?.state?.name]);

  return (
    <>
      <ParallaxWrapper
        sectionRef={sectionRef}
        containerVisibility={containerVisibility}
      />
      <Features
        sectionRef={sectionRef}
        containerVisibility={containerVisibility}
      />
      <Benifits containerVisibility={containerVisibility} />
      <BussinessIntegration
        sectionRef={sectionRef}
        containerVisibility={containerVisibility}
      />
      <Ecosystem
        sectionRef={sectionRef}
        containerVisibility={containerVisibility}
      />
      <QA sectionRef={sectionRef} containerVisibility={containerVisibility} />
    </>
  );
};
