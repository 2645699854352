import { css, styled } from "@mui/material";
import {
  COLUMN_ALIGN_START__JUSTIFY_START,
  DESKTOP_WIDTH,
  GradientFontStyle,
  MOBILE_WIDTH,
  ROW_ALIGN_START__SPACE_B,
  STICKY_POSITION,
  TABLET_WIDTH,
} from "../../styles/global-styles";
import {
  TEXT_11,
  TEXT_16_H24,
  TEXT_18,
  TEXT_36,
  TEXT_40,
  TEXT_50,
  TEXT_56,
} from "../../styles/globalTypography";
import { breakpoints } from "../../styles/media-queries";

export const QAStyled = styled("section")`
  visibility: ${(props) =>
    props?.containerVisibility?.QA ? "visible" : "hidden"};
  max-width: 1296px;
  margin: auto;
  margin-bottom: 357px;
  scroll-margin: 100px;
  ${ROW_ALIGN_START__SPACE_B}
  @media (max-width: ${breakpoints.m1024}px) {
    margin-bottom: 180px;
  }
  @media (min-width: ${breakpoints.sm}px) and (max-width: ${breakpoints.xxl}px) {
    ${DESKTOP_WIDTH}
    gap: 30px;
  }
  @media (max-width: ${breakpoints.sm}px) {
    ${TABLET_WIDTH}
    flex-direction: column;
    margin-bottom: 167px;
  }
  @media (max-width: ${breakpoints.xxs}px) {
    ${MOBILE_WIDTH}
    // margin-top: 446px;
    flex-direction: column;
    margin-bottom: 114px;
  }
  & .left-column {
    max-width: 416px;
    ${STICKY_POSITION}
    top: ${(props) =>
      props.screenHeight > 850 ? "calc(50vh - 288.5px)" : "125px"};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 48px;
    @media (min-width: ${breakpoints.xxs}px) and (max-width: ${breakpoints.sm}px) {
      align-items: center;
      margin-bottom: 33px;
      position: static;
      max-width: unset;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      align-items: center;
      margin-bottom: 57px;
      position: static;
      width: 100%;
    }
  }
  & .qa-button {
    justify-content: flex-start;
    @media (min-width: ${breakpoints.sm}px) {
      padding-left: 0;
    }
  }
  & .qa-button-text {
    min-width: 238px;
    font-weight: 700;
    ${TEXT_11}
  }
  & .title-container {
    ${COLUMN_ALIGN_START__JUSTIFY_START}
  }
  & .black-title {
    width: 100%;
    ${TEXT_56}
    @media (min-width: ${breakpoints.sm}px) and (max-width: ${breakpoints.xxl}px) {
      ${TEXT_50}
    }
    @media (max-width: ${breakpoints.sm}px) {
      ${TEXT_40}
      line-height: 56px;
      text-align: center;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      ${TEXT_36}
      text-align: center;
    }
  }
  & .qa-title-breaker {
    @media (min-width: ${breakpoints.xxs}px) and (max-width: ${breakpoints.sm}px) {
      display: none;
    }
  }
  & .gradient-font {
    ${GradientFontStyle}
    width: fit-content;
    margin: auto;
  }
  & .grey-text {
    width: 100%;
    line-height: 32px;
    letter-spacing: -0.02em;
    @media (min-width: ${breakpoints.xxs}px) and (max-width: ${breakpoints.sm}px) {
      ${TEXT_18}
      text-align: center;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      ${TEXT_16_H24}
    }
  }
  & .contact-text {
    min-width: 95px;
    line-height: 24px;
    letter-spacing: -0.02em;
  }
  & .right-column {
    max-width: 636px;
    @media (min-width: ${breakpoints.sm}px) {
      padding-top: 200px;
      padding-bottom: 200px;
    }
  }
  .magnet-container {
    position: relative;
    width: fit-content;
    cursor: pointer;
    margin: -15px;
  }

  .magnet-item {
    position: relative;
    display: inline-block;
    margin: 15px;
    transition: all 0.75s linear;
  }

  ${(props) =>
    props?.containerVisibility?.QA
      ? css`
          visibility: visible;
          transition: visibility 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94),
            opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
          opacity: 1;
        `
      : css`
          visibility: hidden;
          opacity: 0;
          transition: visibility 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94),
            opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        `}
`;
