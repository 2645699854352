import React, { useEffect, useRef, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Footer } from "./containers/footer";
import { Header } from "./containers/header";
import { ModalProvider } from "./contexts/modal";
import { CustomDrawer } from "./componets/drawer";
import CustomCursor from "./componets/mouse";
import CustomCursorManager from "./contexts/cursor";
import WindowSizeProvider from "./contexts/screen-size";
import { TermsConditions } from "./containers/terms-conditions";
import { IndexPage } from "./containers/Index-page";
import { scrollObservers } from "./utils/scroll-observers";
import Download from "./containers/downloads";
import { ROUTENAMES } from "./constants";
import { styled } from "@mui/material";
import { PrivacyPolicy } from "./containers/privacy-policy";
import { breakpoints } from "./styles/media-queries";

function App() {
  const [toggleDrawer, setToggleDrawer] = useState(false);
  const [containerVisibility, setContainerVisibility] = useState({
    introWrapper: false,
    GoalLayer: false,
    featureIntro: false,
    FF: false,
    SF: false,
    TF: false,
    benifits: false,
    integration: false,
    ecosystem: false,
    QA: false,
    download: false,
  });
  const containerVisibilityRef = useRef(containerVisibility);
  const sectionRef = useRef({
    AboutCorePass: null,
    Features: null,
    Business: null,
    Ecosystem: null,
    Contact: null,
    Hero: null,
  });
  useEffect(() => {
    let { observer10 } = scrollObservers(
      containerVisibilityRef,
      setContainerVisibility
    );
    observer10.observe(document.getElementById("download"));
  }, []);

  return (
    <WindowSizeProvider>
      <Router>
        <CustomCursorManager>
          <ModalProvider>
            <CustomCursor />

            <AppWrapper id="App">
              <Header
                sectionRef={sectionRef}
                setToggleDrawer={setToggleDrawer}
                containerVisibility={containerVisibility}
              />
              <ContentWraper id="Goal">
                <Routes>
                  <Route
                    path={ROUTENAMES.RootPage}
                    element={
                      <IndexPage
                        sectionRef={sectionRef}
                        containerVisibilityRef={containerVisibilityRef}
                        setContainerVisibility={setContainerVisibility}
                        containerVisibility={containerVisibility}
                      />
                    }
                  />
                  <Route
                    path={ROUTENAMES.TermsConditions}
                    element={<TermsConditions />}
                  />
                  <Route
                    path={ROUTENAMES.Privacy}
                    element={<PrivacyPolicy />}
                  />
                </Routes>
                <Download containerVisibility={containerVisibility} />
                <Footer sectionRef={sectionRef} />
              </ContentWraper>
              <CustomDrawer
                toggleDrawer={toggleDrawer}
                setToggleDrawer={setToggleDrawer}
                sectionRef={sectionRef}
              >
                <div
                  onClick={() => {
                    setToggleDrawer(false);
                  }}
                ></div>
              </CustomDrawer>
            </AppWrapper>
          </ModalProvider>
        </CustomCursorManager>
      </Router>
    </WindowSizeProvider>
  );
}

const AppWrapper = styled("div")`
  overflow-x: clip;

  @media (max-width: ${breakpoints.xxs}px) {
    overflow-x: scroll;
  }
`;

const ContentWraper = styled("div")`
  max-width: 1920px;
  margin: auto;
  min-width: 320px;
  @media (max-width: ${breakpoints.xxs}px) {
    overflow-x: clip;
  }
`;

export default App;
