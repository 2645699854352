import { css, styled } from "@mui/material";
import { COLUMN_ALIGN_CENTER__SPACE_B } from "../../styles/global-styles";
import {
  TEXT_11,
  TEXT_16_H24,
  TEXT_18,
  TEXT_36,
  TEXT_40,
  TEXT_56,
} from "../../styles/globalTypography";
import { breakpoints } from "../../styles/media-queries";

export const GoalStyled = styled("section")`
  background-image: linear-gradient(to right, var(--Blue), var(--Light-blue));
  padding: 109px 0 142px;
  border-radius: 16px;
  position: relative;
  z-index: 20;
  ${(props) =>
    props?.containerVisibility?.GoalLayer
      ? css`
          visibility: visible;
          transition: visibility 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94),
            opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
          opacity: 1;
        `
      : css`
          visibility: hidden;
          opacity: 0;
          transition: visibility 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94),
            opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        `}
  @media (max-width: ${breakpoints.sm}px) {
    width: 100%;
    padding-top: 94px;
    padding-bottom: 93px;
    scroll-margin: 100px;
  }
  @media (max-width: ${breakpoints.xxs}px) {
    width: 100%;
    padding-top: 133px;
    padding-bottom: 124px;
    border-top: 1px solid var(--Solitude);
    border-radius: 0;
  }
  & .center-content {
    position: relative;
    ${COLUMN_ALIGN_CENTER__SPACE_B};
    gap: 48px;
    max-width: 1081px;
    width: 80%;
    margin: auto;
    @media (max-width: ${breakpoints.sm}px) {
      width: 72%;
      height: auto;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      width: 82%;
      height: auto;
    }
  }
  & .goal-button-text {
    min-width: 156px;
    height: 13px;
    ${TEXT_11}
    text-transform: uppercase;
    opacity: 0.5;
  }
  & .center-content-3d {
    ${COLUMN_ALIGN_CENTER__SPACE_B};
    gap: 48px;
    width: 100%;
    margin: auto;
    @media (max-width: ${breakpoints.xxs}px) {
      width: 100%;
      height: auto;
    }
  }

  & .white-title {
    width: 70%;
    margin: auto;
    ${TEXT_56}
    text-align: center;

    @media (max-width: ${breakpoints.sm}px) {
      width: 100%;
      ${TEXT_40}
      line-height: 56px;
      text-align: center;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      width: 100%;
      ${TEXT_36}
      text-align: center;
      letter-spacing: -0.07em;
    }
  }
  & .white-body {
    width: 100%;
    line-height: 32px;
    text-align: center;
    letter-spacing: -0.02em;
    @media (max-width: ${breakpoints.sm}px) {
      ${TEXT_18}
      text-align: center;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      ${TEXT_16_H24}
    }
  }

  & .logoTopGoal {
    position: absolute;
    top: -199px;
    left: -198px;
    width: 264px;
    @media (max-width: ${breakpoints.sm}px) {
      width: 211.2px;
      left: -135px;
      top: -162px;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      width: 211.2px;
      left: -118px;
      top: -198px;
    }
  }

  & .logoBottomGoal {
    position: absolute;
    bottom: -314px;
    right: -210px;
    width: 248px;
    @media (max-width: ${breakpoints.sm}px) {
      width: 211.2px;
      bottom: -225px;
      right: -135px;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      width: 211.2px;
      bottom: -258px;
      right: -130px;
    }
  }
  .floating {
    animation-name: floating;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }

  @keyframes floating {
    0%,
    100% {
      transform: translate(0, -40px);
    }
    50% {
      transform: translate(0, 10px);
    }
  }
  .floating-bottom {
    animation-name: floating-bottom;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }

  @keyframes floating-bottom {
    0%,
    100% {
      transform: translate(0, -20px);
    }
    50% {
      transform: translate(0, 40px);
    }
  }
  & .goal-button {
    width: 218px;
    height: 45px;
    border-radius: 40px;
    border: 0;
    padding: 16px 32px;
    position: relative;
    z-index: 0;
    &:hover {
      box-shadow: none;
      cursor: unset;
    }
    ::before {
      content: none;
    }
  }
`;
