import CTypography from "../../componets/typography";
import { DownloadStyled } from "./downloads-styled";
import DownloadIcon from "../../assets/icons/donwload-double-cell.png";
import { CGradientButton } from "../../componets/button/gradient";
import { GooglePlayButton } from "../../componets/button/google-play";
import { AppStoreButton } from "../../componets/button/apple-store";

const Download = ({ containerVisibility }) => {
  return (
    <DownloadStyled containerVisibility={containerVisibility} id="download">
      <div className="content ">
        <div className="download-left-column">
          <CGradientButton className="download-badge">
            <CTypography
              color="var(--Blue)"
              weight="bold"
              className="download-badge-text"
            >
              PUT THE YOU IN UNIQUE
            </CTypography>
          </CGradientButton>

          <div className="download-title-container entrance-animation-download">
            <CTypography
              color="var(--Black)"
              weight="bold"
              className="download-white-title"
            >
              Download now for free,{" "}
              <span className="hide-for-tablet"> available</span>
            </CTypography>
            <CTypography
              color="var(--Black)"
              weight="bold"
              className="download-white-title gradient-font"
            >
              <span className="show-for-tablet"> available</span> worldwide
            </CTypography>
          </div>
          <div className="download-button-container scale-restore entrance-animation-download">
            <GooglePlayButton />
            <AppStoreButton />
          </div>
        </div>

        <img
          src={DownloadIcon}
          alt="DownloadIcon"
          className="download-right-column entrance-animation-download"
        />
      </div>
    </DownloadStyled>
  );
};

export default Download;
