import { CTransparentButton } from "../../componets/button/transparent";
import CTypography from "../../componets/typography";
import { BussinessStyled } from "./bussiness-styled";
import { CBaseButton } from "../../componets/button/base";
import birefcaseIcon from "../../assets/icons/briefcase-new-wob.png";
import corepassWog from "../../assets/icons/corepass-wog-png.png";
import { useContext } from "react";
import { CustomCursorContext } from "../../contexts/cursor";
import { Get_In_Touch_Link, Learn_More_Link } from "../../constants";

const BussinessIntegration = ({ containerVisibility, sectionRef }) => {
  const { setType } = useContext(CustomCursorContext);

  return (
    <BussinessStyled
      ref={(e) => {
        sectionRef.current.Business = e;
      }}
      id="integration"
      containerVisibility={containerVisibility}
    >
      <div className="centerContent ">
        <img
          src={corepassWog}
          alt="corepass-wog"
          className="logoTop floating"
        />
        <CBaseButton className="grey-button">
          <CTypography
            color="var(--Blue)"
            weight="bold"
            className="bussiness-button-text"
          >
            Enterprise Ecosystem
          </CTypography>
        </CBaseButton>
        <CTypography color="var(--Black)" weight="bold" className="white-title">
          Seamless integration for your business
        </CTypography>

        <CTypography color="var(--Grey)" weight="medium" className="white-body">
          CorePass enables organizations to digitize and automate identity
          verification, reducing costs and improving operational efficiency, all
          while simplifying KYC and AML compliance processes, maintaining GDPR
          compliance and adapting to any jurisdiction.{" "}
          <div className="responsive-breaks">
            {" "}
            <br />
          </div>
          CorePass empowers organizations to digitize and automate identity
          verification, thereby reducing costs and enhancing operational
          efficiency. It simplifies KYC and AML compliance processes, ensures
          GDPR compliance, and adapts to any jurisdiction. Tailored to an
          organization's needs, CorePass integrates seamlessly with existing
          systems and services to improve user experience by facilitating
          registration and login without the need for usernames and passwords.
        </CTypography>
        <div className="button-container">
          <div className="magnet-container ">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={Get_In_Touch_Link}
            >
              <CBaseButton
                className="contained-button magnet-item"
                onMouseEnter={() => {
                  setType("hover");
                }}
                onMouseLeave={() => {
                  setType("");
                }}
              >
                <CTypography
                  color="var(--White)"
                  weight="bold"
                  className="contained-button-text"
                  size="larger"
                >
                  Get in touch
                </CTypography>
              </CBaseButton>
            </a>
          </div>
          <div className="magnet-container ">
            <a target="_blank" rel="noopener noreferrer" href={Learn_More_Link}>
              <CTransparentButton
                className="WBorder-button magnet-item"
                onMouseEnter={() => {
                  setType("hover");
                }}
                onMouseLeave={() => {
                  setType("");
                }}
              >
                <CTypography
                  color="var(--Black)"
                  weight="bold"
                  size="larger"
                  className="button-text-white"
                >
                  Learn more
                </CTypography>
              </CTransparentButton>
            </a>
          </div>
        </div>
        <img
          src={birefcaseIcon}
          alt="birefcaseIcon"
          className="logoBottom floating-bottom"
        />
      </div>
    </BussinessStyled>
  );
};

export default BussinessIntegration;
