import { CGradientButton } from "../../../componets/button/gradient";
import CTypography from "../../../componets/typography";
import { StartContentStyled, StartContentWrapper } from "./start-styled";

export const StartContent = ({ containerVisibility, sectionRef }) => {
  return (
    <StartContentWrapper
      id="StartContentWrapper"
      containerVisibility={containerVisibility}
    >
      <StartContentStyled
        id="featureIntro"
        containerVisibility={containerVisibility}
        ref={(e) => {
          sectionRef.current.Features = e;
        }}
      >
        <CGradientButton width="137px">
          <CTypography
            color="var(--Blue)"
            weight="bold"
            className="button-text"
          >
            Features
          </CTypography>
        </CGradientButton>
        <div className="bottom-content">
          <CTypography
            color="var(--Black)"
            weight="bold"
            className="black-title"
          >
            CorePass offers a wide range of
            <CTypography
              color="var(--Black)"
              weight="bold"
              className="black-title hidable-title-section"
            >
              innovative features designed to revolutionize
            </CTypography>
          </CTypography>
          <CTypography weight="bold" className="black-title gradient-font">
            innovative features designed to revolutionize
            <span className="show-for-tablet"> the way you manage, </span>
          </CTypography>
          <CTypography
            color="var(--Black)"
            weight="bold"
            className="black-title-bottom"
          >
            <span className="hide-for-tablet"> the way you manage, </span>{" "}
            share, and secure your digital identity.
          </CTypography>
        </div>
      </StartContentStyled>
    </StartContentWrapper>
  );
};
