import { css, styled } from "@mui/material";
import {
  GradientFontStyle,
  MOBILE_WIDTH,
  ROW_ALIGN_START__JUSTIFY_START,
  TABLET_WIDTH,
} from "../../styles/global-styles";
import { breakpoints } from "../../styles/media-queries";
import {
  TEXT_11,
  TEXT_16_H24,
  TEXT_36,
  TEXT_64,
  TEXT_96,
} from "../../styles/globalTypography";

export const IntroductionWrapper = styled("div")`
  img {
    pointer-events: none;
  }
  @media (max-width: ${breakpoints.sm}px) {
    margin-bottom: 0px !important;
    transform: unset !important;
  }

  ${(props) =>
    !props?.hideCutOff
      ? css`
          visibility: visible;
          transition: visibility 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94),
            opacity 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
          opacity: 1;
        `
      : css`
          visibility: hidden;
          opacity: 0;
          transition: visibility 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94),
            opacity 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        `}
  .slide-in-bottom {
    -webkit-animation: slide-in-bottom 1.5s cubic-bezier(0.1, 0.8, 0.1, 1) both;
    animation: slide-in-bottom 1.5s cubic-bezier(0.1, 0.8, 0.1, 1) both;
    animation-delay: 200ms;
  }

  @-webkit-keyframes slide-in-bottom {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes slide-in-bottom {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
export const IntroductionStyled = styled("section")`
  margin: 93px auto 280px auto;
  display: flex;
  justify-content: center;
  position: relative;
  scroll-margin: 200px;
  @media (max-width: ${breakpoints.sm}px) {
    margin-top: 83px;
  }
  @media (max-width: ${breakpoints.xxs}px) {
    margin-bottom: 0;
    margin-top: 80px;
  }

  & .intro-container {
    ${ROW_ALIGN_START__JUSTIFY_START}
    width: 1296px;
    min-width: 1296px;
    margin: auto;

    @media (min-width: ${breakpoints.lg}px) and (max-width: ${breakpoints.xxl}px) {
      transform: scale(0.8);
      transform-origin: top;
      height: 542.4px;
    }
    @media (min-width: ${breakpoints.sm}px) and (max-width: ${breakpoints.lg}px) {
      transform: scale(0.6);
      transform-origin: top;
      height: 406.8px;
    }
    @media (max-width: ${breakpoints.sm}px) {
      ${TABLET_WIDTH}
      min-width: unset;
      justify-content: center;
      height: unset;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      width: unset;
      min-width: unset;
      justify-content: center;
      height: unset;
    }
  }
  & .leftColumn {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 48px;
    width: 848px;
    @media (max-width: ${breakpoints.sm}px) {
      align-items: flex-start;
      width: 100%;
      height: auto;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      align-items: center;
      ${MOBILE_WIDTH}
      height: auto;
    }
  }
  & .button-text {
    ${TEXT_11}
    min-width: 176px;
  }
  & .middle-section {
    width: 100%;
  }
  & .introTitle {
    ${TEXT_96}
    @media (max-width: ${breakpoints.sm}px) {
      width: 100%;
      ${TEXT_64}
      height: auto;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      width: 100%;
      ${TEXT_36}
      line-height: 41px;
      text-align: center;
      letter-spacing: -0.07em;
    }
  }
  & .gradientFont {
    ${GradientFontStyle}
    height: 105px;
    @media (max-width: ${breakpoints.sm}px) {
      height: auto;
    }
  }

  & .introBody {
    width: 636px;
    letter-spacing: -0.02em;
    line-height: 32px;
    padding-right: 5px;
    @media (max-width: ${breakpoints.sm}px) {
      width: 100%;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      width: 100%;
      padding: 0;
      ${TEXT_16_H24}
    }
  }

  & .intro-badge {
    @media (min-width: ${breakpoints.xxs}px) {
      padding-left: 0 !important;
    }
  }

  & .corepass-button-text {
    letter-spacing: -0.02em;
    line-height: 24px;
    min-width: 116px;
  }

  & .hero-image-container {
    position: absolute;
    top: -99px;
    left: 746px;
    width: 597.41px;
    height: 944.46px;
    @media (max-width: ${breakpoints.sm}px) {
      position: static;
      width: 100%;
      margin-bottom: -138%;
      margin-top: -48px;
      height: unset;
    }
    @media (max-width: ${breakpoints.xs}px) {
      position: static;
      width: 100%;
      margin-bottom: -700px;
      margin-top: -48px;
      height: unset;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      display: none;
    }
  }

  & .introLogo {
    position: absolute;
    top: -99px;
    left: 746px;
    width: 597.41px;
    height: 944.46px;
    @media (max-width: ${breakpoints.sm}px) {
      position: static;
      width: 100%;
      margin-bottom: -138%;
      margin-top: -48px;
      height: unset;
    }

    @media (max-width: ${breakpoints.xs}px) {
      position: static;
      width: 100%;
      margin-bottom: -700px;
      margin-top: -48px;
      height: unset;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      display: none;
    }
  }

  & .intro-mobile-logo {
    display: none;
    @media (max-width: ${breakpoints.xxs}px) {
      display: inline;
      z-index: -1;
      width: 100vw;
      margin-bottom: -225px;
      margin-top: 32px;
      min-width: 375px;
    }
  }

  & .TradeMark-Icon {
    position: absolute;
    z-index: -10;
    right: -339px;
    top: -347px;
    @media (max-width: ${breakpoints.sm}px) {
      display: none;
    }
  }

  .magnet-container {
    position: relative;
    text-align: center;
    cursor: pointer;
    margin: -15px;
  }

  .magnet-item {
    position: relative;
    display: inline-block;
    margin: 15px;
    transition: transform 0.75s linear;
  }

  .icon-container {
    margin-top: 30px;
    width: 100%;
    height: $icon-size;
  }

  .icon {
    cursor: pointer;
    position: relative;
    display: inline-block;
    width: $icon-size;
    height: $icon-size;
    margin-left: $icon-size/5;
    margin-right: $icon-size/5;
    border-radius: $icon-size * $border-radius;
    overflow: hidden;
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      transition: all 0.25s ease;
      border-radius: $icon-size * $border-radius;
    }
    i {
      position: relative;
      color: $white;
      font-size: $icon-size/2;
      margin-top: $icon-size/4;
      transition: all 0.25s ease;
    }
  }

  .icon-fill {
    &::before {
      transition-duration: 0.5s;
      box-shadow: inset 0 0 0 1px $green;
    }
    &:hover::before {
      box-shadow: inset 0 0 0 $icon-size $green;
    }
  }

  // slide-in animation
  .slide-in-bottom {
    -webkit-animation: slide-in-bottom 15s cubic-bezier(0.1, 0.8, 0.1, 1) both;
    animation: slide-in-bottom 15s cubic-bezier(0.1, 0.8, 0.1, 1) both;
    animation-delay: 200ms;
  }

  @-webkit-keyframes slide-in-bottom {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes slide-in-bottom {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
