import { css, styled } from "@mui/material";
import {
  COLUMN_ALIGN_CENTER__SPACE_B,
  COLUMN_JUSTIFY_START__ALIGN_CENTER,
  DESKTOP_WIDTH,
  GradientFontStyle,
  MOBILE_WIDTH,
  TABLET_WIDTH,
} from "../../styles/global-styles";
import {
  TEXT_11,
  TEXT_16_H24,
  TEXT_36,
  TEXT_40,
  TEXT_56,
} from "../../styles/globalTypography";
import { breakpoints } from "../../styles/media-queries";

export const EcosystemStyled = styled("section")`
  visibility: ${(props) =>
    props?.containerVisibility?.ecosystem ? "visible" : "hidden"};

  position: relative;
  ${COLUMN_ALIGN_CENTER__SPACE_B}
  max-width: 1144px;
  margin: auto;
  margin-bottom: 314px;
  scroll-margin: 100px;
  gap: 125px;
  @media (min-width: ${breakpoints.sm}px) and (max-width: ${breakpoints.xxl}px) {
    ${DESKTOP_WIDTH}
  }
  @media (max-width: ${breakpoints.xxs}px) {
    ${MOBILE_WIDTH}
    gap: 86.9px;
    margin-bottom: 120px;
  }
  & .top-frame {
    text-align: center;
    ${COLUMN_JUSTIFY_START__ALIGN_CENTER}
    gap:56px;
    @media (max-width: ${breakpoints.sm}px) {
      ${TABLET_WIDTH}
    }
  }
  & .eco-button-text {
    min-width: 189px;
    ${TEXT_11}
  }

  & .black-title {
    width: 100%;
    ${TEXT_56}
    text-align: center;
    @media (max-width: ${breakpoints.sm}px) {
      ${TEXT_40}
      line-height: 56px;
      text-align: center;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      ${TEXT_36}
      text-align: center;
    }
  }
  & .gradient-font {
    ${GradientFontStyle}
    padding-bottom: 1px;
    line-height: 63px;
    @media (max-width: ${breakpoints.sm}px) {
      line-height: unset;
    }
  }
  & .grey-text {
    max-width: 822px;
    line-height: 32px;
    text-align: center;
    letter-spacing: -0.02em;
    @media (max-width: ${breakpoints.xxs}px) {
      ${TEXT_16_H24}
      padding: 0 10px;
    }
  }
  & .hide-for-tablet {
    @media (min-width: ${breakpoints.xxs}px) and (max-width: ${breakpoints.sm}px) {
      display: none;
    }
  }
  & .show-for-mobile {
    display: none;
    @media (max-width: ${breakpoints.xxs}px) {
      display: inline;
    }
  }
  & .hide-for-mobile {
    display: inline;
    @media (max-width: ${breakpoints.xxs}px) {
      display: none;
    }
  }
  & .show-for-tablet {
    display: none;
    @media (min-width: ${breakpoints.xxs}px) and (max-width: ${breakpoints.sm}px) {
      display: inline;
    }
  }

  ${(props) =>
    props?.containerVisibility?.ecosystem
      ? css`
          visibility: visible;
          transition: visibility 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94),
            opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
          opacity: 1;
        `
      : css`
          visibility: hidden;
          opacity: 0;
          transition: visibility 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94),
            opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        `}

  .img-item {
    position: absolute;
    transition: all 0.5s ease-in-out;
    width: 198px;
    height: 198px;
    @media (min-width: ${breakpoints.xxs}px) and (max-width: ${breakpoints.sm}px) {
      width: 154px;
      height: 154px;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      width: 98px;
      height: 98px;
    }
  }
  img {
    position: absolute;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    width: 198px;
    @media (min-width: ${breakpoints.xxs}px) and (max-width: ${breakpoints.sm}px) {
      width: 154px;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      width: 98px;
    }
    :hover {
      filter: drop-shadow(0px 24px 40px rgba(5, 8, 7, 0.1));
      transition: all 1s ease-in-out;
      scale: 1.2;
      @media (min-width: ${breakpoints.xxs}px) and (max-width: ${breakpoints.sm}px) {
        scale: 1.2;
      }
      @media (max-width: ${breakpoints.xxs}px) {
        scale: 1.2;
      }
    }
  }
  & .top-left-1 {
    left: 0px;
    top: 14px;
    @media (min-width: ${breakpoints.xxs}px) and (max-width: ${breakpoints.sm}px) {
      width: 154px;
      left: 8px;
      top: 3px;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      width: 98px;
      left: 8px;
      top: 3px;
    }
  }
  & .top-left-2-container {
    width: 144px;
    height: 144px;
    position: relative;
    left: 317px;
    top: 0;
    border-radius: 1500px;
    z-index: 100;
    @media (min-width: ${breakpoints.xxs}px) and (max-width: ${breakpoints.sm}px) {
      width: 113.14px;
      height: 113.14px;
      left: 249px;
      top: 0;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      display: none;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      transition: all 0.25s ease;
      border-radius: 1500px;
      transition-duration: 0.5s;
      box-shadow: inset 0 0 0 0px rgba(0, 0, 0, 0.1);
      z-index: 200;
    }
    &:hover::before {
      background-color: transparent;
      box-shadow: inset 0 0 0 144px rgba(0, 0, 0, 0.1);
    }
  }
  & .top-left-2 {
    position: static;
    border-radius: 1500px;
    filter: drop-shadow(0px 24px 40px rgba(16, 102, 223, 0.33));
    width: unset;
    z-index: 100;
    :hover {
      width: unset;
    }
    @media (min-width: ${breakpoints.xxs}px) and (max-width: ${breakpoints.sm}px) {
      width: 113.14px;
      top: 0;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      display: none;
    }
  }
  & .top-left-2-mobile-container {
    display: none;
    width: unset;
    @media (max-width: ${breakpoints.xxs}px) {
      display: inline-block;
      position: relative;
      width: 74.2px;
      height: 74.2px;
      top: 0;
      left: 163px;
    }
    &::before {
      content: "";
      position: absolute;

      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      transition: all 0.25s ease;
      border-radius: 1500px;
      transition-duration: 0.5s;
      box-shadow: inset 0 0 0 0px rgba(0, 0, 0, 0.1);
      z-index: 100;
    }
    &:hover::before {
      background-color: transparent;
      box-shadow: inset 0 0 0 74.2px rgba(0, 0, 0, 0.1);
    }
  }
  & .top-left-2-mobile {
    display: none;
    width: unset;
    :hover {
      width: unset;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      display: inline;
      top: 0;
      // left: 163px;
      // filter: drop-shadow(0px 24px 40px rgba(16, 102, 223, 0.66));
      filter: drop-shadow(0px 10px 40px rgba(16, 102, 223, 0.66));
    }
    :hover {
      filter: drop-shadow(0px 10px 40px rgba(16, 102, 223, 0.66));
    }
  }
  & .top-left-3 {
    right: -1px;
    top: -23px;
    border-radius: 0px;
    animation-delay: -0.75s;
    @media (min-width: ${breakpoints.xxs}px) and (max-width: ${breakpoints.sm}px) {
      width: 154px;
      top: -15px;
      right: 2px;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      width: 98px;
      top: -10px;
      right: 2px;
    }
  }
  & .top-left-4 {
    left: 204px;
    top: 162px;
    animation-delay: -3s;
    @media (min-width: ${breakpoints.xxs}px) and (max-width: ${breakpoints.sm}px) {
      width: 154px;
      left: 162.37px;
      top: 117px;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      width: 98px;
      left: 100.37px;
      top: 79px;
    }
  }
  & .top-left-5 {
    right: -56px;
    top: 147px;
    animation-delay: -1.5s;
    @media (min-width: ${breakpoints.xxs}px) and (max-width: ${breakpoints.sm}px) {
      width: 154px;
      right: -37px;
      top: 138px;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      width: 98px;
      right: -16px;
      top: 91px;
    }
  }
  & .top-left-6 {
    left: 60px;
    top: 298px;
    animation-delay: -4s;
    @media (min-width: ${breakpoints.xxs}px) and (max-width: ${breakpoints.sm}px) {
      width: 154px;
      left: 39.44px;
      top: 227px;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      width: 98px;
      left: 33.44px;
      top: 155px;
    }
  }
  & .top-left-7 {
    right: 146px;
    top: 246px;
    @media (min-width: ${breakpoints.xxs}px) and (max-width: ${breakpoints.sm}px) {
      width: 154px;
      left: 339.95px;
      top: 201px;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      width: 98px;
      left: 219.95px;
      top: 133px;
    }
  }

  & .circle-sm-container {
    position: absolute;
    width: 396px;
    height: 396px;
    left: 194px;
    top: -126px;
    z-index: -5;
    @media (min-width: ${breakpoints.xxs}px) and (max-width: ${breakpoints.sm}px) {
      width: 311.14px;
      height: 311.14px;
      top: -99px;
      left: 152.43px;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      width: 204.06px;
      height: 204.06px;
      top: -64.93px;
      left: 99.97px;
    }
  }
  & .circle-sm {
    width: 396px;
    height: 396px;
    border-radius: 50%;
    border: 0;
    padding: 193px 193px;
    position: relative;
    z-index: 0;
    @media (min-width: ${breakpoints.xxs}px) and (max-width: ${breakpoints.sm}px) {
      width: 311.14px;
      height: 311.14px;
      padding: 155.55px 155.55px;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      width: 204.06px;
      height: 204.06px;
      padding: 102.03px 102.03px;
    }
    ::before {
      content: "";
      position: absolute;
      z-index: -10;
      inset: 0;
      padding: 2px;
      border-radius: 50%;
      background: linear-gradient(
        180deg,
        rgba(104, 110, 132, 0) 0%,
        rgba(104, 110, 132, 0.1) 100%
      );
      -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }
  }
  & .circle-md-container {
    position: absolute;
    width: 596px;
    height: 596px;
    left: 94px;
    top: -226px;
    z-index: -5;
    @media (min-width: ${breakpoints.xxs}px) and (max-width: ${breakpoints.sm}px) {
      width: 468.29px;
      height: 468.29px;
      top: -177.57px;
      left: 73.86px;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      width: 307.12px;
      height: 307.12px;
      top: -116.46px;
      left: 48.44px;
    }
  }
  & .circle-md {
    width: 596px;
    height: 596px;
    border-radius: 50%;
    border: 0;
    padding: 298px 298px;
    position: relative;
    z-index: 0;
    @media (min-width: ${breakpoints.xxs}px) and (max-width: ${breakpoints.sm}px) {
      width: 468.29px;
      height: 468.29px;
      padding: 234.15px 234.15px;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      width: 307.12px;
      height: 307.12px;
      padding: 153.6px 153.6px;
    }
    ::before {
      content: "";
      position: absolute;
      z-index: -10;
      inset: 0;
      padding: 2px;
      border-radius: 50%;
      background: linear-gradient(
        180deg,
        rgba(104, 110, 132, 0) 0%,
        rgba(104, 110, 132, 0.1) 100%
      );
      -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }
  }
  & .circle-lg-container {
    position: absolute;
    width: 784px;
    height: 784px;
    left: 0px;
    top: -320px;
    z-index: -5;
    @media (min-width: ${breakpoints.xxs}px) and (max-width: ${breakpoints.sm}px) {
      width: 616px;
      height: 616px;
      top: -251.43px;
      left: 0px;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      width: 404px;
      height: 404px;
      left: 0;
      top: -164.9px;
    }
  }
  & .circle-lg {
    width: 784px;
    height: 784px;
    border-radius: 50%;
    border: 0;
    padding: 392px 392px;
    position: relative;
    z-index: 0;
    @media (min-width: ${breakpoints.xxs}px) and (max-width: ${breakpoints.sm}px) {
      width: 616px;
      height: 616px;
      padding: 308px;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      width: 404px;
      height: 404px;
      padding: 202px;
    }
    ::before {
      content: "";
      position: absolute;
      z-index: -10;
      inset: 0;
      padding: 2px;
      border-radius: 50%;
      background: linear-gradient(
        180deg,
        rgba(104, 110, 132, 0) 0%,
        rgba(104, 110, 132, 0.1) 100%
      );
      -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }
  }
  .images-container {
    position: relative;
    width: 784px;
    height: 464px;
    @media (min-width: ${breakpoints.xxs}px) and (max-width: ${breakpoints.sm}px) {
      width: 616px;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      width: 404px;
      height: 239px;
    }
  }
  .floating-bottom {
    pointer-events: unset;
    animation-name: floating-bottom;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }

  @keyframes floating-bottom {
    0%,
    100% {
      transform: translate(0, -5px);
    }
    50% {
      transform: translate(0, 5px);
    }
  }
`;
