import React, { useContext } from "react";
import CursorImage from "../../assets/icons/pointer-default.svg";
import { CustomCursorContext } from "../../contexts/cursor";
import { breakpoints } from "../../styles/media-queries";
import { useWindowSize } from "../../contexts/screen-size";
import { StyledCursor } from "./styled-mouse";

const CustomCursor = () => {
  const { windowWidth } = useWindowSize();
  const { type } = useContext(CustomCursorContext);
  const mainCursor = React.useRef(null);
  const positionRef = React.useRef({
    mouseX: 0,
    mouseY: 0,
    destinationX: 0,
    destinationY: 0,
    distanceX: 0,
    distanceY: 0,
    key: -1,
  });

  React.useEffect(() => {
    if (windowWidth >= breakpoints.sm) {
      const handleMouseMove = (event) => {
        const { clientX, clientY } = event;

        const mouseX = clientX;
        const mouseY = clientY;

        // 72 is size of hovered cursor image and 8 is its default
        // mainCursor.current.style.transform = `translate3d(${
        //   mouseX - (type === "hover" ? 72 : 8) / 2 - 4
        // }px, ${mouseY - (type === "hover" ? 72 : 8) / 2 - 4}px, 0)`;
        mainCursor.current.style.transform = `translate3d(${
          mouseX - 8 / 2 - 4
        }px, ${mouseY - 8 / 2 - 4}px, 0)`;
      };
      document.addEventListener("mousemove", handleMouseMove);

      return () => {
        document.removeEventListener("mousemove", handleMouseMove);
      };
    }
  }, [windowWidth, type]);

  React.useEffect(() => {
    if (windowWidth >= breakpoints.sm) {
      const followMouse = () => {
        positionRef.current.key = requestAnimationFrame(followMouse);
        const {
          mouseX,
          mouseY,
          destinationX,
          destinationY,
          distanceX,
          distanceY,
        } = positionRef.current;
        if (!destinationX || !destinationY) {
          positionRef.current.destinationX = mouseX;
          positionRef.current.destinationY = mouseY;
        } else {
          positionRef.current.distanceX = (mouseX - destinationX) * 0.1;
          positionRef.current.distanceY = (mouseY - destinationY) * 0.1;
          if (
            Math.abs(positionRef.current.distanceX) +
              Math.abs(positionRef.current.distanceY) <
            0.1
          ) {
            positionRef.current.destinationX = mouseX;
            positionRef.current.destinationY = mouseY;
          } else {
            positionRef.current.destinationX += distanceX;
            positionRef.current.destinationY += distanceY;
          }
        }
      };
      followMouse();
    }
    const cleanupKey = positionRef.current.key;
    return () => {
      cancelAnimationFrame(cleanupKey);
    };
  }, [windowWidth]);
  return (
    <StyledCursor
      src={CursorImage}
      alt="cursor"
      // className={`main-cursor ${type}`}
      type={type}
      ref={mainCursor}
    />
  );
};

export default CustomCursor;
