import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosIcon from "@mui/icons-material/KeyboardArrowDown";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import CTypography from "../typography";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderTop: "1px solid var(--Solitude)",
  "&:last-child": {
    borderBottom: "1px solid var(--Solitude)",
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosIcon sx={{ fontSize: "1.2rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  padding: 0,
  "& .MuiAccordionSummary-content": {
    margin: "32px 0",
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .black-acc-title": {
    maxWidth: "548px",
    fontSize: "20px",
    lineHeight: "32px",
    letterSpacing: "-0.02em",
    width: "87%",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
  marginBottom: "32px",
  "& .grey-body": {
    maxWidth: "636px",
    lineHeight: "24px",
    letterSpacing: "-0.02em",
  },
}));

export default function CAccordions({ accordionOptions }) {
  const [expanded, setExpanded] = React.useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const accordionOptionsDisplay = accordionOptions?.map?.(({ title, body }) => {
    return (
      <Accordion
        expanded={expanded === title}
        onChange={handleChange(title)}
        key={title}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <CTypography
            weight="medium"
            size="largest"
            color="var(--Black)"
            className="black-acc-title"
          >
            {title}
          </CTypography>
        </AccordionSummary>
        <AccordionDetails>
          <CTypography
            weight="medium"
            size="large"
            color="var(--Grey)"
            className="grey-body"
          >
            {body}
          </CTypography>
        </AccordionDetails>
      </Accordion>
    );
  });

  return accordionOptionsDisplay;
}
