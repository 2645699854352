import { styled } from "@mui/material";

export const FeaturesStyled = styled("section")`
  display: flex;
  flex-direction: column;
  scroll-margin: 100px;
  img {
    pointer-events: none;
  }
`;
