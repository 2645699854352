import { CGradientButton } from "../../componets/button/gradient";
import { CTransparentButton } from "../../componets/button/transparent";
import CTypography from "../../componets/typography";
import { BenifitsStyled } from "./benifits-styled";
import corepassBOW from "../../assets/icons/corepass-bow.svg";
import personWOG from "../../assets/icons/person-wog.svg";
import personForward from "../../assets/icons/person-forward.svg";
import TradeMarkIcon from "../../assets/icons/trademark-3.svg";
import { useContext, useEffect } from "react";
import { movementEffectBen } from "../../utils/3d-move-ben";
import { CustomCursorContext } from "../../contexts/cursor";
import { ModalContext } from "../../contexts/modal";
import { useWindowSize } from "../../contexts/screen-size";

const Benifits = ({ containerVisibility }) => {
  let { setIsOpen } = useContext(ModalContext);
  const { windowHeight } = useWindowSize();
  const { setType } = useContext(CustomCursorContext);
  useEffect(() => {
    movementEffectBen("BenContents", "BenifitBottom");
    movementEffectBen("benifits", "BenifitTop");
  }, []);

  return (
    <BenifitsStyled
      screenHeight={windowHeight.current}
      id="benifits"
      containerVisibility={containerVisibility}
    >
      <div className="ben-contents" id="BenContents">
        <div className="left-column">
          {" "}
          <CGradientButton className="benifits-badge">
            <CTypography
              color="var(--Blue)"
              weight="bold"
              className="ben-button-text"
            >
              Value of your Data
            </CTypography>
          </CGradientButton>
          <CTypography
            color="var(--Black)"
            weight="bold"
            className="black-title"
          >
            Redefining the <br className="left-title-break" /> value of personal
            information and your digital footprint
          </CTypography>
          <CTypography
            color="var(--Grey)"
            weight="medium"
            size="larger"
            className="body-left-column"
          >
            Digital identity, representing a unique collection of personal
            information and credentials, is becoming increasingly important in
            our interconnected world. It's essential for establishing trust
            between individuals and online service providers in a digital
            landscape.
            <br />
            <br /> Rapid adoption of digital identity solutions in governments,
            institutions and organizations, CorePass emerges as a pioneering,
            decentralized platform offering privacy-first, secure, and
            interoperable digital identity management.
          </CTypography>
          <div className="magnet-container ">
            <CTransparentButton
              width="196px"
              height="64px"
              className="magnet-item"
              onClick={() => {
                setIsOpen(true);
              }}
              onMouseEnter={() => {
                setType("hover");
              }}
              onMouseLeave={() => {
                setType("");
              }}
            >
              <CTypography
                color="var(--Black)"
                size="larger"
                weight="bold"
                className="corepass-button-text"
              >
                Get CorePass
              </CTypography>
            </CTransparentButton>
          </div>
          <img
            src={TradeMarkIcon}
            alt="TradeMark-Icon"
            className="trademark-icon"
          />
        </div>
        <div className="right-column">
          <div
            className="top"
            id="BenifitTop"
            style={{ transition: "transform 0.5s linear" }}
            // style={{ transition: "transform 0.25s linear" }}
          >
            <div className="top-content">
              <img
                src={corepassBOW}
                alt="corepassBOW"
                className="benifit-pic"
              />
              <img
                src={personForward}
                alt="personForward"
                className="benifit-pic-mobile"
              />
              <CTypography
                color="var(--Blue)"
                weight="bold"
                className="blue-title"
              >
                Enhance the value of your data
              </CTypography>
              <CTypography
                color="var(--Grey)"
                weight="medium"
                size="larger"
                className="body-right-column"
              >
                With CorePass, you have control over your data, and you build
                its value. By sharing your data with other CorePass users or
                websites that use CorePass as their login tool, you can enhance
                your value and verify the validity using a data-trail.
              </CTypography>
              <ul>
                <li>Your data holds substantial value.</li>
                <li>
                  You are protecting yourself and validating your status with a
                  data-trail.
                </li>
              </ul>
            </div>
          </div>
          <div
            className="top"
            id="BenifitBottom"
            style={{ transition: "transform 0.25s linear" }}
          >
            <div className="top-content">
              <img src={personWOG} alt="personWOG" className="benifit-pic" />
              <img
                src={personForward}
                alt="personForward"
                className="benifit-pic-mobile"
              />
              <CTypography
                color="var(--Black)"
                weight="bold"
                className="black-title-36"
              >
                Secure Decentralization Enabler
              </CTypography>
              <CTypography
                color="var(--Grey)"
                weight="medium"
                size="larger"
                className="body-right-column"
              >
                CorePass empowers you with the benefits of a secure,
                decentralized layer, enabling the exchange of any data
                information worldwide securely.
              </CTypography>
              <ul className="bullet-black">
                <li>Data transfers occur on the decentralized network.</li>
                <li>Built-in fingerprint stamps to validate data.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </BenifitsStyled>
  );
};
export default Benifits;
