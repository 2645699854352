export const Introduction = {
  title: "Introduction",
  body: 'These Terms of Service ("Terms") govern the use of CorePass (the "Application"), a digitization application for data provided by CorePass SA (the "Company"), a company incorporated in Switzerland, with its registered office located in Slovakia.',
  body2:
    'By accessing and/or using the services, you ("user" or "you") have read, understood, and accepted all of the following Terms and agree to be legally bound by these Terms, in their most recent version; You further represent and warrant that: (i) You are of legal age to enter into a binding agreement; and (ii) if you represent a corporation, governmental organization or other legal entity, You have the right, power, and authority to enter into these Terms on behalf of such corporation, governmental organization or other legal entity and to legally bind it to these Terms.',
  body3:
    "Your access and/or use of the services is contingent upon Your acceptance of these Terms.",
  body4:
    "The Company may amend these Terms at any time. Amendments will be effective 14 (fourteen) days after the modified terms and conditions have been posted on the Company’s website at ",
  body4Con:
    " except for amendments that relate to new features or are made for legal reasons, which will become effective immediately. If You do not agree to such modified terms, you should discontinue your use of the services.",
};

export const Service = {
  title: "Service",
  body: `Through the CorePass ID Application, you will be able to create a digital identity and use your personal data to access and use digital platforms requiring such data (the “Services”).`,
  body2:
    "The Application uses cryptographic functions for account creation, executed on the phone. No data from this process is stored on the Company servers. Losing access to the account will result in losing access to your digital identity. The Company is not responsible for any damage caused by losing access to the account.",
};

export const PersonalDataAndPrivacy = {
  title: "Personal Data and Privacy",
  subtitle:
    "CorePass is not collecting your data on any server. You control your data. ",
  body: `You may opt to provide technical details to enhance the application's functionality.`,
  list: "Read more about your data privacy in the Privacy Policy.",
};

export const License = {
  title: "License",
  body: "Unless otherwise stated, CorePass and/or its licensors retain the intellectual property rights for all materials on CorePass, with the exception of digital assets and physical/digital data owned by the client.",
};

export const ProhibitedActivities = {
  title: "Prohibited Activities",
  body: "By accessing and/or using the services, you acknowledge and agree that you shall not use the services if applicable laws, based on your country of location, residency, and/or citizenship, prohibit you from doing so in accordance with these Terms.",
  body2:
    "By accessing and/or using the services, you acknowledge and agree that you shall not use, or assist third parties to use the services in any way which may constitute a contravention of applicable laws or which may contradict the purposes or hinder the operations of the services or hinder the operations of other users of the services.",
  body3:
    "Without limitation to the foregoing, you acknowledge and agree that you shall not use, or assist third parties to use the services to, without limitation, engage in activities that may amount to:",
  list: [
    "Share details of other people.",
    "Republish material from CorePass.",
    "Sell, rent, or sub-license material from CorePass.",
    "Reproduce, duplicate, or copy material from CorePass.",
    "Redistribute content from CorePass.",
    "Refrain from reverse engineering the application.",
    "Avoid distributing applications under a similar brand or any application impersonating CorePass.",
    "Use documents, which are not in your legal custody.",
    "Use CorePass of the account, which is not yours including all data.",
    "Use CorePass for illegal activities.",
    `Engaging in fraudulent, illegal, or malicious activities.
    `,
    "Violating any applicable laws, regulations, or industry standards.",
    "Accessing or attempting to access other users' accounts without their consent.",
  ],
};

export const Rights = {
  title: "Rights",
  body: "You can use the services to:",
  list: [
    "Share your own CoreID.",
    "Share your CoreID or that of another party.",
    "Publish CoreID in another form such as a QR code, NFC, or Hyperlink.",
    "Use information received securely as intended.",
    "Backup your data in a secure location periodically. (Highly recommended.)",
    "Integrate CorePass login and registration system into the website/application.",
  ],
};

export const Requirements = {
  title: "Requirements",
  body: "For a correct use of the services we strongly recomand you to:",
  list: [
    "Ensure the safety of your data. Implement additional protective measures on your phone, such as using PIN codes, biometric authentication, or face recognition. Employ antivirus software, and avoid using printers during backup processes.",
    "Safely store and do not expose your seed phrase and private key.",
    "Safely store and do not expose your PIN code used as additional security.",
  ],
};

export const LimitationOfLiabilities = {
  title: "Limitation of liabilities",
  body: `In no event will the Company, its officers, shareholders, employees, agents, directors, subsidiaries, affiliates, successors, assigns, suppliers, or licensors be liable for any loss or damages, including without limitation, direct, indirect, special, consequential, or punitive loss or damages, arising from or related to your use of the services, whether in an action of contract, tort or otherwise, and regardless of whether such damages were foreseeable and whether or not the Company was advised of the possibility of such damages.
  `,
  body2: `Without limiting the generality of the foregoing, the Company takes no responsibility for and will not be liable for any financial or other loss or damages arising from or related to the use of the services, including, without limitation, to any of the following:`,
  list: [
    `loss of or inability to access the service;`,
    `technical failure, malfunction or shutdown;`,
    `server failure, hacks or unavailability;`,
    `data loss;`,
    `stolen, lost, or unauthorized use of your digital identity;`,
    `loss or expose seed phrase and/or private key, including loss in the access of the related wallet;`,
    `PIN code loss or theft;`,
    `failure to update or provide correct information;`,
    `“phishing” or other websites masquerading as the Company, including cybercrime;`,
    `delays, interruption or loss of services;`,
    `loss of business or goodwill.`,
  ],
  body3: `The service is provided on an “as is” and “as available” basis without any representation or warranty of any kind, whether express or implied, to the maximum extent permitted by applicable laws. The Company disclaims any implied warranties of title, merchantability, fitness for a particular purpose, and/or non-infringement.`,
};

export const Fees = {
  title: "Fees",
  body: "CorePass collects fees through various methods. These fees include service charges and blockchain-related fees. You are responsible for paying all applicable fees.",
  body2: `Due to the nature of digital assets, all payments are non-refundable. We reserve the right to block payments that have not raised disputes through our support but were addressed indirectly. Any disputes can be sent to: support@corepass.net`,
  body3: "The Company reserves the right to modify the fees at any time.",
};

export const IntellectualProperty = {
  title: "Intellectual Property",
  body: "You acknowledge and agree that the services are the property of the Company or its licensors. Subject to your compliance with these Terms, the Company grants you a limited right to access and/or use the services. The right to access and/or use the services is a non-exclusive, non-transferable, revocable, limited license, and it is subject to the limitations and obligations contained herein. Nothing in these Terms gives You any license (other than as set out in this section), right, title, or ownership of, in, or to any of the services.",
  body2:
    "You acknowledge and agree that the Company retains all rights, title, and interest in and to all copyrights, trademarks, trade secrets, patents, and any other proprietary rights in the services, the software and application programming interfaces (APIs) comprising the ervices, and all content therein.",
  body3:
    "You warrant that the use inside the Nemesis platform of any name, logo, image, color, and such other licensee intellectual property rights shall not infringe the rights (including, without limitation, the intellectual property rights) of any third party. You hereby agree to fully and effectively indemnify and keep indemnified UNDO on demand, from and against any and all losses, damages, liabilities, penalties, suits, judgments, and expenses (including reasonable legal expenses) resulting from or arising out of or relating to any breach by you of the warranty given in the preceding sentence.",
  body4:
    "You warrant that any image, video, picture, or any other kind of marketing material executed on the Nemesis platform or showing the Nemesis platform will include the Nemesis logo.",
};

export const SuspensionAndTermination = {
  title: "Suspension and Termination",
  body: "In case of a Force Majeure Event, violation of these Terms, or any other situation that would render providing the services commercially unreasonable for the Company, we may, at our discretion and without liability to you, with or without prior notice, suspend your access to all or part of our services.",
  body2:
    "If you want to suspend your account or replace the current running one, please contact our support team at support@corepass.net.",
};

export const WarrantyDisclaimer = {
  title: "Warranty Disclaimer",
  body: `Unless expressly stated otherwise in these Terms, our services are provided on an "as is" and "as available" basis. We expressly disclaim and you waive all warranties of any kind, whether express or implied, including, without limitation, implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement concerning our services, including the information, content, and materials contained therein.`,
  body2:
    "You acknowledge that the information you store or transfer through our services may become irretrievably lost or corrupted or temporarily unavailable due to various causes, including software failures, protocol changes by third-party providers, internet outages, force majeure events, or other disasters, including third-party DDoS attacks, scheduled or unscheduled maintenance, or other causes either within or outside our control. You are solely responsible for backing up and maintaining duplicate copies of any information you store or transfer through our services.",
};

export const Indemnification = {
  title: "Indemnification",
  body: `To the fullest extent permitted under applicable laws, you agree to hold harmless and indemnify the Company, its officers, shareholders, employees, agents, directors, subsidiaries, affiliates, successors, assigns, suppliers, or licensors from and against all third-party claims and all liabilities, damages, assessments, losses, costs, or expenses (including reasonable attorney fees) resulting from or arising out of (i) your alleged or actual breach of these Terms, including, without limitation, your express representations and warranties; (ii) your alleged or actual use or misuse of the services; and (ii) your alleged or actual infringement or violation of any laws or of the rights of a third party.`,
};

export const LiabilityEvents = {
  title: "Liability for Force Majeure Events",
  body: `The Company shall not be held responsible for:`,
  list: [
    "any inaccuracies, errors, delays, or omissions in",
    `any loss or damage arising from events beyond the Company's reasonable control, including, but not limited to, natural disasters such as floods, extraordinary weather conditions, earthquakes, or other acts of God, fires, wars, insurrections, riots, labor disputes, accidents, actions of government, communication failures, power outages, or equipment or software malfunctions, or any other cause beyond the Company's reasonable control (each, a "Force Majeure Event").    `,
  ],
  indexZeroNestedList: [
    "any information, or",
    "the transmission or delivery of information;",
  ],
};

export const GoverningLaw = {
  title: "Governing Law and Dispute Resolution",
  bodyBlack: `PLEASE READ THE FOLLOWING SECTION CAREFULLY, AS IT REQUIRES YOU TO ARBITRATE DISPUTES WITH US AND LIMITS THE MANNER IN WHICH YOU CAN SEEK RELIEF.`,
  body: `You and the Company agree to arbitrate any dispute arising from these Terms or your use of the Services, with the exception of disputes where either party seeks equitable or other relief for the alleged unlawful use of copyrights, trademarks, trade names, logos, trade secrets, or patents. ARBITRATION PREVENTS YOU FROM SUING IN COURT OR FROM HAVING A JURY TRIAL. You and the Company agree to notify each other in writing of any dispute within thirty (30) days of its occurrence. Notice to the Company should be directed to support@corepass.net. You and the Company further agree:`,
  list: [
    "to attempt informal resolution prior to demanding arbitration;",
    "any dispute, controversy, or claim arising out of, or in relation to, this contract, including regarding the validity, invalidity, breach, or termination thereof, shall be resolved by arbitration in accordance with the Swiss Rules of International Arbitration of the Swiss Arbitration Centre in force on the date on which the Notice of Arbitration is submitted in accordance with those Rules;",
    "that arbitration will be conducted confidentially by a single arbitrator;",
    "the seat of the arbitration shall be Lugano;",
    "the arbitral proceedings shall be conducted in English.",
  ],
};

export const AvailabilityAndChanges = {
  title: "Availability and Changes",
  body: `Terms can be accessed on the CorePass Website `,
  bodyCon: ` and through a link in the application. Kindly note that CorePass may modify terms from time to time. The modified version will be uploaded to the CorePass Website. `,
  body2: `Users are advised to review terms periodically for any changes. Updates are considered active upon posting.`,
};
