import { css, styled } from "@mui/material";
import bulletFirst from "../../../assets/icons/bullet-first.svg";
import {
  TEXT_16,
  TEXT_20_500,
  TEXT_40,
  TEXT_56,
} from "../../../styles/globalTypography";
import { breakpoints } from "../../../styles/media-queries";
import {
  BULLET_STYLE,
  MOBILE_WIDTH,
  ROW_ALIGN_START__SPACE_B,
  TABLET_WIDTH,
} from "../../../styles/global-styles";

export const FirstFeatureWrapper = styled("div")`
  background-color: var(--White);
  position: relative;
  z-index: 10;
`;
export const FirstFeatureStyled = styled("div")`
  visibility: ${(props) =>
    props?.containerVisibility?.FF ? "visible" : "hidden"};
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 104px;
  max-width: 1296px;
  justify-content: center;
  width: 100%;
  margin-bottom: 234px;
  @media (max-width: ${breakpoints.sm}px) {
    margin-bottom: 79px;
  }
  @media (max-width: ${breakpoints.xxs}px) {
    margin-bottom: 132px;
  }

  & .FF-container {
    margin: auto;
    ${ROW_ALIGN_START__SPACE_B}
    gap: 104px;
    max-width: 1296px;
    min-width: 1296px;

    @media (min-width: ${breakpoints.lg}px) and (max-width: ${breakpoints.xxl}px) {
      transform: scale(0.8);
      transform-origin: top;
      height: 511px;
    }
    @media (min-width: ${breakpoints.sm}px) and (max-width: ${breakpoints.lg}px) {
      min-width: 1296px;
      transform: scale(0.6);
      transform-origin: center 0;
      height: 384.4px;
    }
    @media (max-width: ${breakpoints.sm}px) {
      flex-direction: column;
      align-items: center;
      ${TABLET_WIDTH}
      gap: 79px;
      min-width: unset;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      flex-direction: column;
      align-items: center;
      ${MOBILE_WIDTH}
      gap: 64px;
    }
  }

  & .pic-column {
    width: 568px;
    transform: translate(-12px, -2px);
    @media (max-width: ${breakpoints.sm}px) {
      width: 100%;
      transform: unset;
      padding: 0 40px;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      width: 100%;
      transform: unset;
      padding: 0;
    }
  }
  & .text-column {
    max-width: 636px;
    min-width: 636px;
    display: flex;
    flex-direction: column;
    gap: 64px;
    margin-top: 159px;
    @media (max-width: ${breakpoints.sm}px) {
      flex-direction: column;
      width: 100%;
      margin: 0;
      min-width: unset;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      flex-direction: column;
      width: 100%;
      margin: 0;
    }
  }
  & .top-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 36px;
  }
  & .black-title {
    ${TEXT_56}
    @media (max-width: ${breakpoints.sm}px) {
      ${TEXT_40}
      line-height: 56px;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      ${TEXT_40}
      line-height: 56px;
      letter-spacing: -0.07em;
    }
  }
  & .grey-body {
    width: 100%;
    line-height: 32px;
    letter-spacing: -0.02em;
    @media (max-width: ${breakpoints.xxs}px) {
      ${TEXT_16}
    }
  }
  & ul {
    ${TEXT_56}
    letter-spacing: -2px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    & li {
      max-width: 588px;
      ${TEXT_20_500}
      margin-left: 48px;
      position: relative;
      &:before {
        ${BULLET_STYLE}
        background-image: url(${bulletFirst});
      }
    }
  }

  ${(props) =>
    props?.containerVisibility?.FF
      ? css`
          visibility: visible;
          transition: visibility 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94),
            opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
          opacity: 1;
        `
      : css`
          visibility: hidden;
          opacity: 0;
          transition: visibility 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94),
            opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        `}
`;
