import { css, styled } from "@mui/material";
import {
  BULLET_STYLE,
  COLUMN_ALIGN_START__JUSTIFY_START,
  COLUMN_CENTER,
  DESKTOP_WIDTH,
  MOBILE_WIDTH,
  ROW_ALIGN_START__SPACE_B,
  STICKY_POSITION,
  TABLET_WIDTH,
} from "../../styles/global-styles";
import bulletThird from "../../assets/icons/bullet-third.svg";
import bulletBlack from "../../assets/icons/bullet-black.svg";
import {
  TEXT_11,
  TEXT_16_H24,
  TEXT_18,
  TEXT_20_500,
  TEXT_32,
  TEXT_36,
  TEXT_40,
  TEXT_50,
  TEXT_56,
} from "../../styles/globalTypography";
import { breakpoints } from "../../styles/media-queries";

export const BenifitsStyled = styled("section")`
  ${(props) =>
    props?.containerVisibility?.benifits
      ? css`
          visibility: visible;
          transition: visibility 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94),
            opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
          opacity: 1;
        `
      : css`
          visibility: hidden;
          opacity: 0;
          transition: visibility 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94),
            opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        `};
  ${ROW_ALIGN_START__SPACE_B}
  max-width: 1296px;
  margin: auto;
  margin-bottom: 427px;
  position: relative;
  justify-content: center;
  width: 100%;
  @media (max-width: ${breakpoints.m1024}px) {
    margin-bottom: 213px;
  }
  & .ben-contents {
    ${ROW_ALIGN_START__SPACE_B}
    width: 1296px;
    margin: auto;
    position: relative;
    @media (min-width: ${breakpoints.sm}px) and (max-width: ${breakpoints.xxl}px) {
      ${DESKTOP_WIDTH}
      gap: 30px;
    }
    @media (max-width: ${breakpoints.sm}px) {
      flex-direction: column;
      ${TABLET_WIDTH}
      margin-bottom: 120px;
      align-items: center;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      flex-direction: column;
      ${MOBILE_WIDTH}
      margin-bottom: 322px;
    }
  }
  @media (max-width: ${breakpoints.sm}px) {
    margin-bottom: 120px;
  }
  @media (max-width: ${breakpoints.xxs}px) {
    margin-bottom: 22px;
  }
  & .ben-button-text {
    min-width: 156px;
    height: 13px;
    ${TEXT_11}
  }
  & .right-column {
    max-width: 639px;
    ${COLUMN_CENTER}
    gap:96px;
    z-index: 1;
    @media (min-width: ${breakpoints.sm}px) {
      padding-top: 200px;
      padding-bottom: 200px;
    }
  }
  & .left-column {
    max-width: 526px;
    ${STICKY_POSITION}
    flex-shrink: 1.1;

    top: ${(props) =>
      props.screenHeight > 950 ? "calc(50vh - 382px)" : "125px"};
    display: flex;
    ${COLUMN_ALIGN_START__JUSTIFY_START}
    gap: 48px;
    @media (max-width: ${breakpoints.sm}px) {
      position: static;
      align-items: center;
      margin-bottom: 115px;
      width: 100%;
      max-width: 648px;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      position: static;
      align-items: center;
      margin-bottom: 143px;
    }
  }
  & .left-title-break {
    @media (max-width: ${breakpoints.sm}px) {
      display: none;
    }
  }
  & .benifits-badge {
    @media (min-width: ${breakpoints.sm}px) {
      padding-left: 0 !important;
    }
  }

  & .black-title {
    width: 100%;
    ${TEXT_56}
    @media (min-width: ${breakpoints.sm}px) and (max-width: ${breakpoints.xxl}px) {
      ${TEXT_50}
    }
    @media (max-width: ${breakpoints.sm}px) {
      ${TEXT_40}
      line-height: 56px;
      text-align: center;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      ${TEXT_40}
      text-align: center;
      letter-spacing: -0.07em;
    }
  }
  & .black-title-36 {
    max-width: 463px;
    font-weight: 700;
    ${TEXT_36}
    @media (max-width: ${breakpoints.xxs}px) {
      ${TEXT_32}
    }
  }

  & .body-left-column {
    line-height: 32px;
    letter-spacing: -0.02em;
    @media (max-width: ${breakpoints.sm}px) {
      ${TEXT_18}
      text-align: center;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      ${TEXT_16_H24}
    }
  }
  & .body-right-column {
    line-height: 32px;
    letter-spacing: -0.02em;
    @media (max-width: ${breakpoints.sm}px) {
      ${TEXT_18}
    }
    @media (max-width: ${breakpoints.xxs}px) {
      ${TEXT_18}
    }
  }
  & .corepass-button-text {
    letter-spacing: -0.02em;
    line-height: 24px;
    min-width: 116px;
  }
  & .blue-title {
    ${TEXT_36}
    @media (max-width: ${breakpoints.xxs}px) {
      ${TEXT_32}
      line-height: 36px;
    }
  }
  & .top {
    background-color: var(--White);
    box-shadow: 0px 32px 48px 0px #101e420d;
    width: 100%;
    padding: 86px;
    border-radius: 24px;
    ${COLUMN_ALIGN_START__JUSTIFY_START}
    gap: 48px;
    @media (min-width: ${breakpoints.sm}px) and (max-width: ${breakpoints.xxl}px) {
      padding: 43px;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      padding: 0px;
      gap: 32px;
      box-shadow: unset;
    }
  }
  & .top-content {
    width: 100%;
    ${COLUMN_ALIGN_START__JUSTIFY_START}
    gap: 48px;
    @media (max-width: ${breakpoints.xxs}px) {
      gap: 32px;
    }
  }
  & .benifit-pic {
    margin-left: -30px;
    margin-bottom: -30px;
    @media (max-width: ${breakpoints.xxs}px) {
      display: none;
    }
  }
  & .benifit-pic-mobile {
    display: none;
    @media (max-width: ${breakpoints.xxs}px) {
      display: inline;
    }
  }
  & .trademark-icon {
    position: absolute;
    z-index: -10;
    top: -295px;
    left: 484px;
    @media (max-width: ${breakpoints.sm}px) {
      display: none;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      display: none;
    }
  }

  & ul {
    ${TEXT_56}
    letter-spacing: -2px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;

    & li {
      ${TEXT_20_500}
      margin-left: 48px;
      position: relative;
      &:before {
        ${BULLET_STYLE}
        background-image: url(${bulletThird});
      }
    }
  }
  & .bullet-black {
    & li {
      &:before {
        background-image: url(${bulletBlack});
      }
    }
  }
  .magnet-container {
    position: relative;
    text-align: center;
    cursor: pointer;
    margin: -15px;
  }
  .magnet-item {
    position: relative;
    display: inline-block;
    margin: 15px;
    transition: all 0.75s linear;
  }
`;
