import CTypography from "../../componets/typography";
import LinkedinIcon from "../../assets/icons/iconsLinkedin.svg";
import InstagramIcon from "../../assets/icons/iconsInstagram.svg";
import XIcon from "../../assets/icons/xIcon.svg";
import YoutubeIcon from "../../assets/icons/youtubeIcon.svg";
import FacebookIcon from "../../assets/icons/facebookIcon.svg";
import FooterLogo from "../../assets/icons/Logo-footer.svg";
import { FooterStyled } from "./footer-styled";
import { FooterNavigation } from "./components/footer-nav";
import { useContext } from "react";
import { CustomCursorContext } from "../../contexts/cursor";
import {
  Facebook_Link,
  Instagram_Link,
  Linkedin_Link,
  X_Link,
  YouTube_Link,
  ROUTENAMES,
} from "../../constants";
import { useWindowSize } from "../../contexts/screen-size";
import { useNavigate, useLocation } from "react-router-dom";
import { scrollToSection } from "../header/utils/scroll-section";
import { flushSync } from "react-dom";
export const Footer = ({ sectionRef }) => {
  const { setType } = useContext(CustomCursorContext);
  const { pathname } = useLocation();
  const { windowWidth } = useWindowSize();
  const navigate = useNavigate();

  const executeScroll = ({ name }) => {
    if (pathname !== ROUTENAMES.RootPage) {
      flushSync(() => {
        navigate(ROUTENAMES.RootPage, { state: { name } });
      });
    } else {
      scrollToSection(sectionRef, name, windowWidth);
    }
  };
  const handlePrivacyClick = () => {
    navigate(ROUTENAMES.Privacy);
    // window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handleTermsClick = () => {
    navigate(ROUTENAMES.TermsConditions);
    // window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <FooterStyled>
      <div className="upper-section">
        <img
          src={FooterLogo}
          alt="FooterLogo"
          className="footer-logo"
          onClick={() => {
            executeScroll({ name: "Hero" });
          }}
          onMouseEnter={() => {
            setType("hover");
          }}
          onMouseLeave={() => {
            setType("");
          }}
        />
        <FooterNavigation executeScroll={executeScroll} />
        <div className="social-section">
          <div
            className="magnet-container"
            onMouseEnter={() => {
              setType("hover");
            }}
            onMouseLeave={() => {
              setType("");
            }}
          >
            <a target="_blank" rel="noopener noreferrer" href={X_Link}>
              <img src={XIcon} alt="XIcon" className="magnet-item" />
            </a>
          </div>
          <div
            className="magnet-container"
            onMouseEnter={() => {
              setType("hover");
            }}
            onMouseLeave={() => {
              setType("");
            }}
          >
            <a target="_blank" rel="noopener noreferrer" href={Facebook_Link}>
              <img
                src={FacebookIcon}
                alt="FacebookIcon"
                className="magnet-item"
              />
            </a>
          </div>
          <div
            className="magnet-container"
            onMouseEnter={() => {
              setType("hover");
            }}
            onMouseLeave={() => {
              setType("");
            }}
          >
            <a target="_blank" rel="noopener noreferrer" href={YouTube_Link}>
              <img
                src={YoutubeIcon}
                alt="YoutubeIcon"
                className="magnet-item"
              />
            </a>
          </div>
          <div
            className="magnet-container"
            onMouseEnter={() => {
              setType("hover");
            }}
            onMouseLeave={() => {
              setType("");
            }}
          >
            <a target="_blank" rel="noopener noreferrer" href={Instagram_Link}>
              <img
                src={InstagramIcon}
                alt="InstagramIcon"
                className="magnet-item"
              />
            </a>
          </div>
          <div
            className="magnet-container"
            onMouseEnter={() => {
              setType("hover");
            }}
            onMouseLeave={() => {
              setType("");
            }}
          >
            <a target="_blank" rel="noopener noreferrer" href={Linkedin_Link}>
              <img
                src={LinkedinIcon}
                alt="LinkedinIcon"
                className="magnet-item"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="bottom-section">
        <div className="footer-copyright">
          <CTypography
            weight="bold"
            color="var(--River-bed)"
            className="footer-text"
          >
            © 2023 CorePass. All Rights Reserved.
          </CTypography>
        </div>
        <div className="policy-condition-wrapper">
          {/* <a
            target="_blank"
            rel="noopener noreferrer"
            href={Privacy_Policy_Link}
          > */}
          <CTypography
            weight="bold"
            color="var(--River-bed)"
            className="footer-text hoverable"
            onMouseEnter={() => {
              setType("hover");
            }}
            onMouseLeave={() => {
              setType("");
            }}
            onClick={handlePrivacyClick}
          >
            Privacy policy
          </CTypography>
          {/* </a> */}
          <div className="vertical-line"></div>
          {/* <a
            target="_blank"
            rel="noopener noreferrer"
            href={Terms_Conditions_Link}
          > */}
          <CTypography
            weight="bold"
            color="var(--River-bed)"
            className="footer-text hoverable"
            onMouseEnter={() => {
              setType("hover");
            }}
            onMouseLeave={() => {
              setType("");
            }}
            onClick={handleTermsClick}
          >
            Terms and Conditions
          </CTypography>
          {/* </a> */}
        </div>
      </div>
    </FooterStyled>
  );
};
