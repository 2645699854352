export const accordionOptions = [
  {
    title: "How can I verify my identity?",
    body: `You can verify your identity directly within the CorePass application. First, you need to purchase a voucher that will allow you to verify the intended field. Each field may have a different value. To start the verification process for a specific field, use your voucher.`,
  },
  {
    title: "How do I request data?",
    body: `To request data from another party, you need to know their Core ID, click the link on the web, or scan the QR code. Select the fields you wish to request. The app will then check the availability of those fields. Then you can initiate the request process and specify the waiting time.`,
  },
  {
    title: "How do I provide data?",
    body: `To provide data, you need to share your Core ID with the other party. This can be done by sharing the Core ID or showing the QR code. After the requester has completed their part, you will receive a notification to provide your data. You have the options to accept, decline, cancel, or even block such requests.`,
  },
  {
    title: "How to login / register on the website?",
    body: `Logging in or registering is as simple as scanning a QR code or clicking on a link. CorePass will then automatically log you in, or, in case of registration, it will ask for some necessary information. You're not obligated to provide the data if you choose not to.`,
  },
  {
    title: "What are the service charges, and how are they calculated?",
    body: `We charge fees for successful data exchanges. These fees have multiple components and vary for each requested field. At the base, there are application fees, network verification fees, and data consistency fees. However, additional fees may be involved depending on the field you're requesting.`,
  },
  {
    title: "Where is the data stored?",
    body: `Your data is stored on your device and encrypted. You have the choice to provide data to another party using the data exchange. This will be automatically validated by a data trail connected to your Core ID.`,
  },
  {
    title: "What should I do if my phone is lost/stolen/inoperable?",
    body: `You should have a backup of your data to recover it, which can be done in the settings of the CorePass application, it is highly recommended to do it regularly. Alternatively, you can start with a brand new Core ID. Install the CorePass application on your new phone from a verified store. After installation, create a new account and recover your data. Please contact us at support@corepass.net to invalidate your previous account. After successful invalidation, you can use your new Core ID. Be sure to update any references with your new Core ID. Although your previous phone's data is protected with encryption and data protection, we advise remotely wiping the phone if possible (for example, using an antivirus program).`,
  },
  {
    title: "How can I recover from a backup?",
    body: `Recovering your backup can be done in various ways. First, let's explain what the backup consists of. A successful backup includes your Core ID account and personal data. Each backup is protected by your PIN and password. Without a valid PIN, you cannot unlock your account. To recover your backup, please select the recovery process either while creating a new account or through the application's settings.`,
  },
  {
    title:
      "Why am I unable to send or receive requests on a Peer-to-Peer (P2P) network?",
    body: `Certain networks may have complex configurations that hinder the functionality of P2P networks. In such scenarios, it's advisable to contact your network provider or examine your router for any potential issues. P2P technology is on the forefront of network innovations, yet its widespread implementation can be slow in various parts of the world. You can help accelerate its adoption by requesting this feature from your provider. At times, you might need to reconfigure or even replace your router to facilitate P2P connections. Additionally, using a VPN might resolve the issue in some less common situations.`,
  },
];
