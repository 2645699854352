import { TransparentButtonStyled } from "./styled-transparent";

export const CTransparentButton = ({
  children,
  height,
  width,
  className,
  ...props
}) => {
  return (
    <TransparentButtonStyled
      height={height}
      width={width}
      {...props}
      className={className}
    >
      {children}
    </TransparentButtonStyled>
  );
};
