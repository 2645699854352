import * as React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { CustomCursorContext } from "../../contexts/cursor";

const LightTooltip = styled(({ className, marginBottom, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, marginBottom }) => ({
  cursor: "pointer",
  [`& .${tooltipClasses.arrow}`]: {
    "&:before": {
      backgroundColor: "white",
      boxShadow: "0px 16px 32px rgba(0, 0, 0, 0.1)",
    },
    color: theme.palette.common.red,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    padding: "16px 32px",
    gap: "10px",
    isolation: "isolate",
    marginTop: `${marginBottom} !important`,
    "@media (max-width:500px)": {
      marginTop: `calc( ${marginBottom} + 20px) !important `,
    },

    background: "#FFFFFF",
    boxShadow: "0px 16px 32px rgba(0, 0, 0, 0.1)",
    borderRadius: "100px",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: "center",
    letterSpacing: "-0.02em",
    color: "#686E84",
  },
}));

export const CustomizedTooltips = React.memo(
  ({ children, title, marginBottom = "-20px", className }) => {
    const { setType } = React.useContext(CustomCursorContext);
    const onMouseEnter = () => {
      setType("hover");
    };
    const onMouseLeave = () => {
      setType("");
    };
    return (
      <LightTooltip
        arrow
        title={title}
        marginBottom={marginBottom}
        TransitionProps={{ timeout: 600 }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={className}
      >
        {children}
      </LightTooltip>
    );
  },
  () => true
);
