export const scrollObservers = (
  containerVisibilityRef,
  setContainerVisibility
) => {
  function callbackFunc(entries, observer) {
    entries.forEach((entry) => {
      if (containerVisibilityRef[entry.target.id] !== entry.isIntersecting) {
        setContainerVisibility((prev) => {
          return { ...prev, [entry.target.id]: entry.isIntersecting };
        });
      }
    });
  }
  let options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.3,
  };
  let optionsInitialBorder = {
    root: null,
    rootMargin: "0px",
    threshold: 0,
  };
  let options1 = {
    root: null,
    rootMargin: "0px",
    threshold: 0.5,
  };
  let options10 = {
    root: null,
    rootMargin: "0px",
    threshold: 0.15,
  };

  let observer = new IntersectionObserver(callbackFunc, options);
  let observerInitialBorder = new IntersectionObserver(
    callbackFunc,
    optionsInitialBorder
  );
  let observer1 = new IntersectionObserver(callbackFunc, options1);
  let observer10 = new IntersectionObserver(callbackFunc, options10);

  return { observer, observerInitialBorder, observer1, observer10 };
};
