import { breakpoints } from "../../../styles/media-queries";

export const scrollToSection = (
  sectionRef,
  name,
  windowWidth = breakpoints.lg
) => {
  const scrollTop = window.scrollY || document.documentElement.scrollTop;
  const scrollMarginNeeded =
    scrollTop * 0.3 < 270 ? 370 - scrollTop * 0.3 : 100;

  if (name === "About CorePass" && windowWidth >= breakpoints.sm) {
    const goalElement = document?.getElementById("GoalLayer");
    if (!goalElement) return;
    const goalElementPosition = goalElement.getBoundingClientRect().top;
    window.scrollBy({
      top: goalElementPosition - scrollMarginNeeded,
      behavior: "smooth",
    });
  } else if (name === "Features" && windowWidth >= breakpoints.sm) {
    const featureElement = document?.getElementById("StartContentWrapper");
    if (!featureElement) return;
    const featureElementPosition = featureElement.getBoundingClientRect().top;
    window.scrollBy({
      top: featureElementPosition - scrollMarginNeeded + 200,
      behavior: "smooth",
    });
  } else if (name === "Business" && windowWidth >= breakpoints.sm) {
    const integrationElement = document?.getElementById("integration");
    if (!integrationElement) return;
    const integrationElementPosition =
      integrationElement.getBoundingClientRect().top;
    window.scrollBy({
      top: integrationElementPosition - scrollMarginNeeded,
      behavior: "smooth",
    });
  } else if (name === "Ecosystem" && windowWidth >= breakpoints.sm) {
    const ecosystemElement = document?.getElementById("ecosystem");
    if (!ecosystemElement) return;
    const ecosystemElementPosition =
      ecosystemElement.getBoundingClientRect().top;
    window.scrollBy({
      top: ecosystemElementPosition - scrollMarginNeeded,
      behavior: "smooth",
    });
  } else if (name === "Contact" && windowWidth >= breakpoints.sm) {
    const QAElement = document?.getElementById("QA");
    if (!QAElement) return;
    const QAElementPosition = QAElement.getBoundingClientRect().top;
    window.scrollBy({
      top: QAElementPosition - scrollMarginNeeded,
      behavior: "smooth",
    });
  } else {
    const spaceLessName = name.replace(/ /g, "");
    sectionRef.current[spaceLessName].scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }
};
