export const movementEffectBen = (container, element) => {
  let constrainy = 2400;
  let constrainx = 1400;
  let mouseOverContainerr = document.getElementById(container);
  let ex1Layerr = document.getElementById(element);
  function transforms(x, y, el) {
    let box = el.getBoundingClientRect();
    let calcX = -(y - box.y - box.height / 2) / constrainx;
    let calcY = (x - box.x - box.width / 2) / constrainy;

    return (
      "perspective(100px) " +
      "   rotateX(" +
      calcX +
      "deg) " +
      "   rotateY(" +
      calcY +
      "deg) "
    );
  }

  function transformElement(el, xyEl) {
    el.style.transform = transforms.apply(null, xyEl);
  }

  mouseOverContainerr.onmousemove = function (e) {
    let xy = [e.clientX, e.clientY];
    let position = xy.concat([ex1Layerr]);

    window.requestAnimationFrame(function () {
      transformElement(ex1Layerr, position);
    });
  };
};
