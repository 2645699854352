import { CGradientButton } from "../../componets/button/gradient";
import CTypography from "../../componets/typography";
import { EcosystemStyled } from "./ecosystem-styled";
import heyo from "../../assets/icons/Heyo.png";
import corePass from "../../assets/icons/CorePass.svg";
import wallMoney from "../../assets/icons/Wall Money.png";
import corePay from "../../assets/icons/CorePay.png";
import coreToken from "../../assets/icons/Core Token.png";
import coreCoin from "../../assets/icons/Core Coin.png";
import pingExchange from "../../assets/icons/Ping Exchange.png";
import corepassMobile from "../../assets/icons/CorePass-mobile.svg";
import { CustomizedTooltips } from "../../componets/tooltip";
import { CustomCursorContext } from "../../contexts/cursor";
import { useContext } from "react";
import { PLATFORM_URLS } from "./platform-urls";

const Ecosystem = ({ containerVisibility, sectionRef }) => {
  const { setType } = useContext(CustomCursorContext);
  const handleItemClick = (url) => {
    console.log("clicked");
    // window.location.href = "https://coreblockchain.net/";
    window.open(url, "_blank");
  };
  return (
    <EcosystemStyled
      ref={(e) => {
        sectionRef.current.Ecosystem = e;
      }}
      id="ecosystem"
      containerVisibility={containerVisibility}
    >
      <div className="top-frame">
        <CGradientButton width="250px">
          <CTypography
            color="var(--Blue)"
            weight="bold"
            className="eco-button-text"
          >
            Unlock the ecosystem
          </CTypography>
        </CGradientButton>
        <div>
          <CTypography
            color="var(--Black)"
            weight="bold"
            className="black-title"
          >
            Unlock endless possibilities with{" "}
            <span className="hide-for-tablet"> CorePass</span>
            <span className="show-for-mobile"> at the</span>
          </CTypography>
          <CTypography weight="bold" className="black-title gradient-font">
            <span className="show-for-tablet"> CorePass</span>{" "}
            <span className="hide-for-mobile"> at the </span>
            heart of the Core Ecosystem.
          </CTypography>
        </div>

        <CTypography
          weight="medium"
          size="largest"
          color="var(--Grey)"
          className="grey-text"
        >
          CorePass is your key to unlocking the Core Ecosystem, providing
          seamless access to a wide array of innovative blockchain services
          designed for the future.
        </CTypography>
      </div>
      <div
        className="images-container"
        // onClick={() => console.log("parent clicked")}
      >
        <CustomizedTooltips title="Heyo">
          <div className="top-left-1 img-item">
            <img
              onMouseEnter={() => {
                setType("hover");
              }}
              onMouseLeave={() => {
                setType("");
              }}
              src={heyo}
              alt="heyo"
              className="floating-bottom"
            />
          </div>
        </CustomizedTooltips>
        <div className="top-left-2-container">
          <img src={corePass} alt="corePass" className="top-left-2" />
        </div>
        <div className="top-left-2-mobile-container">
          <img
            src={corepassMobile}
            alt="corepassMobile"
            className="top-left-2-mobile"
          />
        </div>
        <CustomizedTooltips title="Wall Money">
          <div className="top-left-3 img-item">
            <img
              src={wallMoney}
              alt="wallMoney"
              className="floating-bottom"
              onMouseEnter={() => {
                setType("hover");
              }}
              onMouseLeave={() => {
                setType("");
              }}
            />
          </div>
        </CustomizedTooltips>
        <CustomizedTooltips title="Core Pay">
          <div className="top-left-4 img-item">
            <img
              src={corePay}
              alt="corePay"
              className="floating-bottom"
              onMouseEnter={() => {
                setType("hover");
              }}
              onMouseLeave={() => {
                setType("");
              }}
            />
          </div>
        </CustomizedTooltips>
        <CustomizedTooltips title="Core Token">
          <div className="top-left-5 img-item">
            <img
              src={coreToken}
              alt="coreToken"
              className="floating-bottom"
              onMouseEnter={() => {
                setType("hover");
              }}
              onMouseLeave={() => {
                setType("");
              }}
              onClick={() => handleItemClick(PLATFORM_URLS.CoreToken)}
            />
          </div>
        </CustomizedTooltips>
        <CustomizedTooltips title="Core Coin">
          <div className="top-left-6 img-item">
            <img
              src={coreCoin}
              alt="coreCoin"
              className="floating-bottom"
              onMouseEnter={() => {
                setType("hover");
              }}
              onMouseLeave={() => {
                setType("");
              }}
              onClick={() => handleItemClick(PLATFORM_URLS.CoreBlockchain)}
            />
          </div>
        </CustomizedTooltips>
        <CustomizedTooltips title="Ping Exchange">
          <div className="top-left-7 img-item">
            <img
              src={pingExchange}
              alt="pingExchange"
              className=" floating-bottom"
              onMouseEnter={() => {
                setType("hover");
              }}
              onMouseLeave={() => {
                setType("");
              }}
            />
          </div>
        </CustomizedTooltips>
        <div className="circle-sm-container">
          <div className="circle-sm"></div>
        </div>
        <div className="circle-md-container">
          <div className="circle-md"></div>
        </div>
        <div className="circle-lg-container">
          <div className="circle-lg"></div>
        </div>
      </div>
    </EcosystemStyled>
  );
};

export default Ecosystem;
