import CTypography from "../../../componets/typography";
import { FirstFeatureStyled, FirstFeatureWrapper } from "./first-styled";
import DigitalizationIcon from "../../../assets/icons/Digitalization.png";

export const FirstFeatue = ({ containerVisibility }) => {
  return (
    <FirstFeatureWrapper>
      <FirstFeatureStyled id="FF" containerVisibility={containerVisibility}>
        <div className="FF-container">
          <img
            src={DigitalizationIcon}
            alt="DigitalizationIcon"
            className="pic-column entrance-animation"
          />
          <div className="text-column entrance-animation">
            <div className="top-section">
              <CTypography
                color="var(--Black)"
                weight="bold"
                className="black-title"
              >
                Digitization as a service
              </CTypography>
              <CTypography
                color="var(--Grey)"
                weight="medium"
                size="larger"
                className="grey-body"
              >
                Simplify the process of digitizing government-issued documents
                and establish a verified digital identity that's both easily
                accessible and shareable. With CorePass, you can consolidate
                your ID information, passport, driver's license, and more, all
                within one secure, decentralized application.
              </CTypography>
            </div>
            <ul>
              <li>
                Quick and convenient access to services and personal information
                anytime, anywhere.
              </li>
              <li>
                Minimize manual input errors and ensure data accuracy and
                reliability.
              </li>
            </ul>
          </div>
        </div>
      </FirstFeatureStyled>
    </FirstFeatureWrapper>
  );
};
