import { GoalStyled } from "./goal-styled";
import personPng from "../../assets/icons/person-bow-new.png";
import { CTransparentButton } from "../../componets/button/transparent";
import CTypography from "../../componets/typography";
import CorepassWobIcon from "../../assets/icons/corepass-wob.png";
import { useEffect } from "react";
import { movementEffect } from "../../utils/3d-move";

const Goal = ({ containerVisibility, sectionRef }) => {
  useEffect(() => {
    movementEffect("App", "GoalLayer");
  }, []);

  return (
    <GoalStyled
      ref={(e) => {
        sectionRef.current.AboutCorePass = e;
      }}
      containerVisibility={containerVisibility}
      id="GoalLayer"
    >
      <div className="center-content">
        <img
          src={CorepassWobIcon}
          alt="corepass-wob"
          className="logoTopGoal floating"
        />
        <div
          className="center-content-3d"
          style={{ transition: "transform 0.25s linear" }}
        >
          <CTransparentButton className="goal-button">
            <CTypography
              color="var(--White)"
              weight="bold"
              className="goal-button-text"
            >
              The bigger picture
            </CTypography>
          </CTransparentButton>

          <CTypography
            color="var(--White)"
            weight="bold"
            className="white-title"
          >
            An indispensable tool for navigating the digital future.
          </CTypography>
          <CTypography
            color="var(--White)"
            weight="medium"
            size="largest"
            className="white-body"
          >
            In today's world, digital identity holds increasing significance,
            encompassing a unique compilation of personal data and credentials.
            As countries and industries adopt digital identity solutions,
            CorePass emerges as a pioneering platform that prioritizes privacy
            and offers decentralized digital identity management.
          </CTypography>
        </div>
        <img
          src={personPng}
          alt="person"
          className="logoBottomGoal floating-bottom"
        />
      </div>
    </GoalStyled>
  );
};
export default Goal;
