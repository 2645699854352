import { FirstFeatue } from "./components/first-feature";
import { SecondFeature } from "./components/second-feature";
import { ThirdFeature } from "./components/third-feature";
import { FeaturesStyled } from "./features-styled";

const Features = ({ containerVisibility, sectionRef }) => {
  return (
    <FeaturesStyled>
      <FirstFeatue containerVisibility={containerVisibility} />
      <SecondFeature containerVisibility={containerVisibility} />
      <ThirdFeature containerVisibility={containerVisibility} />
    </FeaturesStyled>
  );
};

export default Features;
