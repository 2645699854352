import { css, styled } from "@mui/material";
import {
  COLUMN_ALIGN_CENTER__SPACE_B,
  GradientFontStyle,
  MOBILE_WIDTH,
  TABLET_WIDTH,
} from "../../../styles/global-styles";
import {
  TEXT_11,
  TEXT_24,
  TEXT_40,
  TEXT_48_CENTER,
} from "../../../styles/globalTypography";
import { breakpoints } from "../../../styles/media-queries";

export const StartContentWrapper = styled("div")`
  background-color: var(--White);
  position: relative;
  z-index: 10;
`;
export const StartContentStyled = styled("div")`
  ${COLUMN_ALIGN_CENTER__SPACE_B}
  visibility: ${(props) =>
    props?.containerVisibility?.featureIntro ? "visible" : "hidden"};
  max-width: 1076px;
  margin: auto;
  padding-bottom: 128px;
  padding-top: 218px;
  background-color: var(--White);

  gap: 48px;
  @media (max-width: ${breakpoints.sm}px) {
    ${TABLET_WIDTH}
    padding-bottom: 66px;
    padding-top: 126px;
  }
  @media (max-width: ${breakpoints.xxs}px) {
    ${MOBILE_WIDTH}
    padding-bottom: 134px;
    padding-top: 140px;
  }
  & .button-text {
    width: 73px;
    ${TEXT_11}
  }
  & .bottom-content {
    width: 100%;
  }
  & .black-title {
    width: 100%;
    ${TEXT_48_CENTER}
    padding: 0 16px;
    @media (max-width: ${breakpoints.sm}px) {
      ${TEXT_40}
      line-height: 56px;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      ${TEXT_24}
      padding: 0 10px;
    }
  }
  & .hidable-title-section {
    display: none;
    @media (max-width: ${breakpoints.xxs}px) {
      display: inline;
    }
  }
  & .black-title-bottom {
    width: 100%;
    ${TEXT_48_CENTER}
    padding: 0 16px;
    @media (max-width: ${breakpoints.sm}px) {
      ${TEXT_40}
      line-height: 56px;
    }
    @media (max-width: ${breakpoints.xxs}px) {
      ${TEXT_24}
      padding: 0 10px;
      ${GradientFontStyle}
      margin: auto;
    }
  }
  & .gradient-font {
    ${GradientFontStyle}
    margin: auto;
    padding-bottom: 1px;
    @media (max-width: ${breakpoints.xxs}px) {
      display: none;
    }
  }

  & .hide-for-tablet {
    @media (min-width: ${breakpoints.xxs}px) and (max-width: ${breakpoints.sm}px) {
      display: none;
    }
  }

  & .show-for-tablet {
    display: none;
    @media (max-width: ${breakpoints.sm}px) {
      display: inline;
    }
  }

  ${(props) =>
    props?.containerVisibility?.featureIntro
      ? css`
          visibility: visible;
          transition: visibility 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94),
            opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
          opacity: 1;
        `
      : css`
          visibility: hidden;
          opacity: 0;
          transition: visibility 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94),
            opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        `}
`;
